import { useEffect, useState, useRef } from "react"
import {
    DiventryBlock
} from './Layout'
import { PlanTable } from './PlanTable';


export function Plan({ endpoint }) {

      const [data, setData] = useState(null);

  useEffect(() => {
    // Fetch the JSON data
    fetch(endpoint) // The path should be relative to the public directory
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(setData)
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  if (!data) {
    return <div>Loading...</div>;
    }
    

    return <DiventryBlock><div className="main"><PlanTable data={data} /></div></DiventryBlock>
}

