
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { AutoComplete } from 'antd';
import { ThreatDBTag } from '../../../Templates/Tag';
import { Content } from '../../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Spin,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Image,
    InputNumber,
    ColorPicker
} from 'antd'

import { DiventryMenu } from '../../../Templates/Menu'

import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../../Templates/Layout'

export function SearchIOC({ kernel }) {
    const navigate = useNavigate()

    const [searchText, setSearchText] = useState(localStorage.getItem('searchOICText'))
    const [loading, setLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState([])
    const [searchType, setSearchType] = useState("")

    useEffect(() => {
        const delayDebounce = setTimeout(async () => {
            const ret = await kernel.api.get(`/threatdb/lookup?query=${searchText}`);
            if (ret.error) {
                setSearchOptions([]);
                return;
            }

            const newOptions = [];
            console.log(ret.data.keys)
            for (var key of ret.data.keys) {
                const tagsShow = key.tags.map((tag) => {
                    return (<ThreatDBTag key={`${key.value}-${tag}`} kernel={kernel} tag={tag} />);
                });

                newOptions.push({
                    value: key.value,
                    label: <>{tagsShow}</>
                });
            }
            setSearchType(ret.data.type);
            setSearchOptions(newOptions);
            // console.log(newOptions)
        }, 500);

        return () => clearTimeout(delayDebounce);
    }, [searchText]);

    const columns = [
        {
            title: 'IP / network / domain',
            dataIndex: 'value',
            key: 'value',
            render: (text, record, index) => {
                return text
            }
        },
        {
            title: 'Tags',
            dataIndex: 'label',
            key: 'label',
            render: (text, record, index) => {
                return text
            }
        },
        {
            // title: 'id',
            dataIndex: 'value',
            key: 'value',
            align: "right",
            render: (text, record, index) => {
                return (<Space.Compact size="small">
                    <Button type="default" onClick={() => {
                        console.log(record.label)
                        navigate(`/threatdb/show/${searchType}?index=${text}`)
                        
                    }}>Details</Button>
    
                </Space.Compact>)
            }
        }
    ]

    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="Search for IOC"
                description="This page is for the search for IOC"
            />
            <DiventryLayoutContent>
                <DiventryBlock bottom={true}>
                    <Spin spinning={loading}>
                        <Space.Compact>
                            <Input
                                style={{ width: 400 }}
                                placeholder='IP, network or domain'
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value)
                                    localStorage.setItem('searchOICText', e.target.value)
                                }}
                            />
                            {/* <Button type="primary" onClick={doSearch}>
                                Search
                            </Button> */}
                        </Space.Compact>
                    </Spin>
                </DiventryBlock>

                <Table
                    // bordered={true}
                    pagination={{ position: ['topLeft', 'bottomRight'] }}
                    ellipsis={true}
                    loading={loading}
                    showSizeChanger={true}
                    dataSource={searchOptions}
                    columns={columns}
                />
                
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutThreatDB>

    );
}