
import {
    Button,
    Form,
    Input,
    Modal,
    Select,
    Switch,
    Tabs,
    Upload,
    notification
} from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"




import { InboxOutlined } from '@ant-design/icons'
import * as XLSX from "xlsx"
import EditableTable from './EditableTable'
import InsertExample from './InsertExample.png'

const { Dragger } = Upload;

const tabs = [
    {
      key: '1',
      label: 'Manuel'
    },
    {
      key: '2',
      label: 'File'
    }
];

const ipv46_regex = /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm;
const domain_regex = /(\*\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/g;

export default function InsertIndicators({ kernel, list }) {

    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)
    const [uploaded, setUploaded] = useState(false)
    const [uploadedData, setUploadedData] = useState([])
    const [dataList, setDataList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [info, setInfo] = useState({
        permissions: {}
    })
    const [form] = Form.useForm();

    const type = Form.useWatch('type', form);


    const navigate = useNavigate()

    // async load
    useEffect(() => {
        async function fetchInformation() {
            const raw = await kernel.api.get(`/threatdb/list/get/${list}`)
            if (!raw || raw.error || !raw.data) return // (navigate("/threatdb/list"))
            setInfo(raw.data)
        }
        fetchInformation()
    }, [loaded])

    const config = {
        name: 'file',
        multiple: false,
        accept: ".csv",
        beforeUpload(info) {
            handleFileUpload(info)
            return false;
        },
        onDrop(e) {
          handleFileUpload(e.dataTransfer.files?.[0])
          return false
        },
      };

      const handleFileUpload = (info) => {
        if (info.type != "text/csv") {
            notification.error({
                message: 'File not supported',
                description: "Only CSV files are supported"
            })
            return 
        }
        var reader = new FileReader();
        reader.onload = function(e) {
            var file = e.target.result;
            var wb = XLSX.read(file);

            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];    
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            setUploadedData(data);
            setUploaded(true);
        };
        reader.readAsArrayBuffer(info);
      }

    // Source handler
    const handleOk = async () => {
        const data = currentTab == 1 ? [form.getFieldsValue()] : dataList?.map(item => (formatIndicator(item)))?.filter(item => item.type != -1);
        while(data?.length) {
            const ret = await kernel.api.post(`/threatdb/list/insert/${list}`, data.splice(0, 500))
            if (!ret || ret.error) {
                notification.error({
                    message: 'Error adding source',
                    description: ret.error
                })
            }
            else {
                notification.success({
                    message: 'Indicator(s) have been pushed into the queue'
                })
            }
        }
        setIsModal(false)
    }

    const formatIndicator = (indicator) => {
        const domainIp = indicator?.['ip/domain'] ?? indicator?.['domain/ip'] ?? indicator?.['domain'] ?? indicator?.['ip'];;
        var data = {};
    
        // check IPV4 or IPV6
        if (ipv46_regex.test(domainIp)) {  
            data.type = 0;
            data.ip = domainIp;
        }
        else if (domain_regex.test(domainIp)) {
            data.type = 1;

            // format domain
            const t = domainIp.split(".");
            if (t[0] === "*") {
                t.shift();
                data.wildcard = true;
            }
            data.domain = t.join(".");
        }
        else {
            data.type = -1;
        }
        data.tags = indicator?.tags?.trim()?.split(' ');
        return data;
    }

    const handleCancel = () => {
        setUploaded(false)
        setIsModal(false)
    }

    return (
        <>
            <Modal
                title="Add list indicators"
                open={isModal}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
            >
                {
                    <div>
                        <Tabs defaultActiveKey="1" items={tabs} onChange={(key) => setCurrentTab(key)}/>
                        {
                            currentTab == 2 ?
                            <div style={{marginTop: 30}}>
                                {
                                    uploaded ?
                                    <EditableTable ignoreCells={['comments']} arrayCells={['tags']} headers={uploadedData?.[0]} setDataList={setDataList} dataList={uploadedData?.filter((item, index) => index > 0)}/> 
                                    :
                                    <Form
                                        form={form}
                                        layout="vertical"
                                    >
                                        <Form.Item label="Mass loading file" name="file">
                                            <Dragger {...config}>
                                                <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">Upload your csv file</p>
                                                <p className="ant-upload-hint">
                                                    <img src={InsertExample} width={430} />
                                                </p>
                                            </Dragger>
                                        </Form.Item>
            
                                    </Form>
                                }
                            </div>
                            :
                            <Form
                                form={form}
                                style={{marginTop: 30}}
                            >
                                <Form.Item label="Type of input" name="type">
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Select"
                                        options={[
                                            { value: 0, label: "IP address or network range" },
                                            { value: 1, label: "Domain name" },
                                        ]}
                                    />
                                </Form.Item>

                                {type === 0 ?
                                    <Form.Item label="IP Address or network CIDR" name="ip">
                                        <Input />
                                    </Form.Item>
                                    : null}


                                {type === 1 ?
                                    <>
                                        <Form.Item label="Domain name" name="domain">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="This is a wildcard" name="wildcard">
                                            <Switch />
                                        </Form.Item>
                                    </>
                                    : null}

                                <Form.Item label="Tags" name="tags">
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder="Type to add tags informations"
                                    />
                                </Form.Item>

                            </Form>

                        }
                    </div>
                }
            </Modal>

            {
                (info?.permissions?.admin || info?.permissions?.write) &&
                <Button type="primary" onClick={() => {
                    form.resetFields()
                    setIsModal(true)
                }}>Insert indicators</Button>
            }
        </>
    )
}
