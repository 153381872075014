
import { useEffect, useState, useRef } from "react"
import { useParams, useLocation, useNavigate, Link } from "react-router-dom"

import {
    Tabs,
    Space,
    Button,
    Dropdown,
    Form,
    Modal,
    Input,
    notification
} from 'antd';

import {
    CaretDownOutlined
} from '@ant-design/icons'


import { DiventryMenu } from '../../Templates/Menu'
import {
    DiventryLayoutDohzel,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
} from '../../Templates/Layout'

import { Business } from './Business'
import { City } from './City'
import { Private } from './Private'

export function Null() {
    return (<></>)
}

const pages = {
    'Business': {
        key: 'Business',
        title: "DOHzel Business",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Business
    },
    'City': {
        key: 'City',
        title: "DOHzel City",
        description: "Securely exchange and share identity on Hafnova software",
        ref: City
    },
    'Private': {
        key: 'Private',
        title: "DOHzel Private",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Private
    },
}


export function Plans({ kernel }) {
    const navigate = useNavigate()
    const params = useParams()
    const [tabs, setTabs] = useState([])

    useEffect(() => {
        setTabs(Object.values(pages))
    }, [])

    return (
        <DiventryLayoutDohzel>

            <DiventryMenu kernel={kernel} />

            <DiventryLayoutContent>
                <DiventryBlock>
                    <Tabs
                        type="card"
                        activeKey={params.page1}
                        onChange={(tab) => {
                            navigate(`/plans/${tab}`)
                        }}
                        items={tabs.map((item) => {
                            return ({
                                key: item.key,
                                label: item.title,
                                children: <div style={{paddingTop: 10}}>
                                    <item.ref kernel={kernel} />
                                    </div>
                            })
                        })}
                    />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>

        </DiventryLayoutDohzel>
    )
}