
import { useState } from 'react'
import { useParams, Link, useNavigate } from "react-router-dom"

import {
    Button,
    Select,
    Form,
    Input,
    Col,
    Space,
    Spin,
    theme
} from 'antd'

import {
    DiventryLayout,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter
} from '../Templates/Layout'

import { DiventryMenu } from '../Templates/Menu'
export function Dashboard({ kernel }) {
    return (
        <DiventryLayout>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="Account Informations"
                description="This is the page descript"
            />
            <DiventryLayoutContent>

                vghfdsu
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>
        </DiventryLayout>
    );
}