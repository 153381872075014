
import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"
import { Content } from '../../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber
} from 'antd'
import { CaretLeftOutlined } from '@ant-design/icons'

const processors = {
    // create
    created: (record) => {
        return (<>New {record.type} entry <strong>{record.name}</strong></>)
    },
    updated: (record) => {
        return (<>{record.type.ucFirst()} <strong>{record.name}</strong> has been updated with tags {Object.keys(record.tags).join(",")}</>)
    },
    deleted: (record) => {
        return (<>Deleting {record.type} <strong>{record.name}</strong></>)
    },
}
const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record, index) => {
            return (new Date(text).toLocaleString())
        }
    },
    {
        title: 'Information',
        dataIndex: 'info',
        key: 'info',
        render: (text, record, index) => {
            if (processors[record.msg]) {
                const str = processors[record.msg](record)
                return (str)
            }

            const show = { ...record }
            delete show.date
            delete show.key
            delete show.list
            var dtf = []
            for (var k in show) {
                dtf.push(`${k}=${show[k]}`)
            }
            return (dtf.join(" "))
        }
    },
    {
        title: 'ID',
        dataIndex: 'id',
        align: "right"
    }
]

export function Logs({ kernel, list }) {
    const [loaded, setLoaded] = useState(true)
    const [logs, setLogs] = useState({ data: [] })

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        async function fetch() {
            // const newTable = []
            const next = searchParams.get('next')
            const params = []
            if (next)
                params.push(`gte=${next}`)

            const raw = await kernel.api.get(`/threatdb/list/logs/${list}?${params.join("&")}`)
            if (raw.error || !raw.data) {
                notification.error({
                    message: 'Error getting API',
                    description: raw.error
                })
                return (navigate("/list/list"))
            }

            setLogs(raw.data)
        }
        fetch()
    }, [loaded, searchParams])

    return (<Table
        size="small"
        pagination={false}
        dataSource={logs.data}
        columns={columns} />)
}