
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Content } from '../../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber
} from 'antd'

import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutCyberIntel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../../Templates/Layout'

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
    },
    {
        title: '',
        dataIndex: 'buttons',
        key: 'buttons',
        align: "right"
    },
]

export function List({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [table, setTable] = useState([])

    // for list creation
    const [isModalCreate, setIsModalCreate] = useState(false)
    const [createForm] = Form.useForm()

    const navigate = useNavigate()

    useEffect(() => {
        async function fetch() {
            const newTable = []
            const raw = await kernel.api.get('/cyberintel/instances/list')

            if (Array.isArray(raw.data)) {
                for (let data of raw.data) {
                    data.key = data.id

                    // process button
                    data.buttons = <Space.Compact size="small">
                        <Popconfirm
                            placement="bottom"
                            title="Delete this instance"
                            description="The instance will be permanently deleted"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={async () => {
                                const ret = await kernel.api.post(
                                    `/cyberintel/instances/remove`,
                                    { id: data.key }
                                )
                                if (ret.error) {
                                    notification.error({
                                        message: 'Error deleting key'
                                    })
                                    return
                                }

                                notification.success({
                                    message: 'Entry was deleted'
                                })

                                setLoaded(!loaded)
                            }}
                        >
                            <Button >Delete</Button>
                        </Popconfirm>


                        <Button
                            onClick={async () => {
                                navigate(`/cyberintel/instances/${data.key}/configuration`)
                            }}
                        >Detail</Button>
                    </Space.Compact>
                    newTable.push(data)
                }
                setTable(newTable)
            }


        }
        fetch()
    }, [loaded])

    // Create handler
    const handleCreateOk = async () => {
        const data = {
            ...createForm.getFieldsValue()
        }
        const ret = await kernel.api.post(`/cyberintel/instances/create`, data)
        if (!ret || ret.error) {
            notification.error({
                message: 'Error updating information'
            })
            return
        }
        notification.success({
            message: 'New list created'
        })
        setIsModalCreate(false)
        // navigate(`/cyberintel/list/detail/${ret.data.id}`)
    }
    const handleCreateCancel = () => {
        setIsModalCreate(false)
    }

    return (
        <DiventryLayoutCyberIntel>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="CyberIntel Instances"
                description="Active CyberIntel instances"
            />
            <DiventryLayoutContent>

                <Modal title="Creating an instance" open={isModalCreate} onOk={handleCreateOk} onCancel={handleCreateCancel}>
                    <Form
                        // layout="vertical"
                        form={createForm}
                    >
                        <Form.Item label="Instance name" name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Is instance public" name="public">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" />
                        </Form.Item>

                    </Form>
                </Modal>

                <p>
                    <Space>

                        <Button onClick={() => {
                            createForm.resetFields()
                            setIsModalCreate(true)
                        }}>
                            Create new instance
                        </Button>
                    </Space>

                </p>
                <DiventryBlock>
                    <Table dataSource={table} columns={columns} />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>
        </DiventryLayoutCyberIntel>

    );
}