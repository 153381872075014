import {
    Tag,
} from 'antd'

export { Tags } from './Tags'
export { Search } from './Search'
export { Show } from './Show'
export { AIVerify } from './AI'
export * as List from './List/index'


export function ShowRetention({ kernel, last, retention }) {
    return (
        <>
            {retention === 0 ?
                "Permanent"
                :
                new Date(last + (retention * 1000)).toLocaleString()
            }
        </>
    )
}

export function ShowTag({ key, kernel, tag }) {
    return (<Tag bordered={false} key={key} color={kernel.tags.list[tag]?.color}>{tag}</Tag>)
}