
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Content } from '../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Spin,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Image,
    InputNumber,
    ColorPicker
} from 'antd'

import { DiventryMenu } from '../../Templates/Menu'

import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../Templates/Layout'

export function AIVerify({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [result, setResult] = useState(null)

    const doSearch = async () => {
        setLoading(true)
        const data = {
            url: search
        }
        const ret = await kernel.api.post(`/threatdb/ai/verify`, data)
        if (!ret || ret.error) {
            notification.error({
                message: `Analize error: ${ret.error}`,
            })
            setLoading(false)
            return
        }
        notification.success({
            message: 'Analize completed'
        })
        setResult(ret.data)
        setLoading(false)
    }

    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="AI Verify"
                description="URL verification based on artificial intelligence"
            />
            <DiventryLayoutContent>
                <DiventryBlock bottom={true}>
                    <Spin spinning={loading}>
                        <Space.Compact>
                            <Input
                                style={{ width: 400 }}
                                placeholder='URL to verify'
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                            <Button type="primary" onClick={doSearch}>
                                Search
                            </Button>
                        </Space.Compact>
                    </Spin>
                </DiventryBlock>

                {result?.links ?
                    <>
                        <DiventryBlock>
                            {result ?
                                <pre>
                                    {JSON.stringify(result.domain, undefined, 2)}
                                </pre>
                                : null}
                        </DiventryBlock>
                        {result.links.length > 0 ?
                            <DiventryBlock>
                                <Image.PreviewGroup
                                    preview={{
                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                    }}
                                >
                                    {result.links.map((link) => {
                                        return (<Image style={{padding: 10}} width="32%" src={kernel.api.link(`/threatdb/ai/verify/screenshot/${link.sum}`)} />)
                                    })}
                                </Image.PreviewGroup>
                            </DiventryBlock>
                            : null}
                    </>
                    : null}
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutThreatDB>

    );
}