import { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import {
    Button,
    Select,
    Form,
    Input,
    Col,
    Space,
    Spin,
    theme,
    notification
} from 'antd';

import { DiventryLayoutSimple } from '../../Templates/LayoutSimple'

const item = {
    wrapperCol: { span: 16, offset: 8 },
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}
export function ChangePassword({ kernel }) {
    let params = useParams();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const onFinish = async (values) => {
        setLoading(true)
        const response = await kernel.api.post('/user/password/token', { ...values, token: params.token })

        if (response.error) {
            notification.error({
                message: <>{response.error}</>
            })
            setLoading(false)
            return
        }
        notification.success({
            message: `Your password has changed`
        })


        kernel.session.store(response.data.token)
        setLoading(false)
        navigate("/")
    }

    const onFinishFailed = async (errorInfo) => {
        // console.log('Failed:', errorInfo);
    }

    return (
        <DiventryLayoutSimple>
            <Form
                {...layout}
                name="basic"
                style={{
                    // maxWidth: 600,
                }}
                initialValues={{
                    // remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Password"
                    name="password1"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Verification"
                    name="password2"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...item}>

                    <Button type="primary" htmlType="submit" loading={loading}>
                        Change password
                    </Button>

                </Form.Item>

                <Col span={16} offset={8} style={{ fontSize: "0.9em" }}>
                    <Link to="/session/login">Finally no, go to the login page</Link>
                </Col>
            </Form>
        </DiventryLayoutSimple>
    )
}
