import { useEffect, useState } from 'react'
import { ConfigProvider, Row, Col, Menu, theme } from 'antd'
import { Link } from "react-router-dom";

import LogoBW from './logo-bw.png'

const { useToken } = theme;

export const DiventryLayoutImages = [
    "img1.png",
    "img2.png",
    "img3.png",
    "img4.png",
    "img5.png",
    "img6.png",
]

export function DiventryLayoutHeader(props) {
    const [title, setTitle] = useState(props.title)
    const [description, setDescription] = useState(props.description)

    const { token: theme } = useToken()

    useEffect(() => {
        setTitle(props.title);
        setDescription(props.description);
    }, [props]);

    return (
        <div style={{
            fontFamily: "Montserrat",
            background: theme.colorInfo,
        }}>
            <div style={{
                maxWidth: 1440,
                paddingTop: 15,
                margin: "0 auto",
            }}>
                <div style={{

                    paddingTop: 20,
                    paddingLeft: 10,
                    paddingBottom: 20,
                    width: "100%",

                }}>
                    <div style={props.noStyle !== true ? {
                        fontWeight: 700,
                        color: "rgb(231,231,231)",
                        fontSize: 48,
                    } : { color: "rgb(231,231,231)" }}>{title}</div>

                    {description ?
                        <div style={{
                            paddingTop: 5,
                            fontSize: 12,
                            color: "rgb(231,231,231)",
                            fontStyle: "italic"
                        }}>{description}</div>
                        : null}
                </div>
            </div>
        </div>

    );
}


export function DiventryLayoutContent({ children }) {
    const { token: theme } = useToken()
    return (
        <div style={{

            fontFamily: "Montserrat",
            background: "rgba(231,231,231,0.5)",
            paddingTop: 10,
            paddingBottom: 10
        }}>
            <div style={{
                // borderTop: "4px solid RGBA(246,246,244,0.5)",
                borderRadius: theme.borderRadius,
                background: "rgba(231,231,231,1)",
                maxWidth: 1440,
                padding: 15,
                margin: "0 auto",
            }}>
                {children}
            </div>
        </div>
    )
}

export function DiventryLayoutFooter(props) {
    const [loaded] = useState(true)
    const [version, setVersion] = useState({ software: '-' })

    useEffect(() => {
        async function fetch() {
            const response = await props.kernel.api.get('/version')
            if (response.error)
                return
            setVersion(response.data)
        }
        fetch()
    }, [loaded]);

    return (<>
        {/* Second top menu */}
        <div style={{
            background: "rgba(218,223,228,0.5)",
            borderTop: "4px solid RGBA(246,246,244,0.5)",
        }}>
            <div style={{
                fontSize: "0.8em",
                padding: 10,
                paddingTop: 50,
                paddingBottom: 50,
                margin: "0 auto",
                // width: "100%",
                background: "rgba(246,246,244,0.3)",
            }}>

                <Row >
                    <Col span={8} align="center" style={{ fontSize: "0.8em", opacity: 0.1 }}>
                        <img src={LogoBW} width={100} />
                    </Col>
                    <Col span={8} style={{ fontSize: "0.9em" }}>
                        © <strong>Hafnova</strong> 2024<br />
                        <i>Current version v{version.software}</i><br />
                    </Col>
                    <Col span={8}>
                        <ul>
                            <li><a href="https://www.hafnova.com" target="_blank">Contact</a></li>
                            <li><a href="https://www.hafnova.com/" target="_blank">Homepage</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </div>
    </>
    );
}

export function DiventryBlock({ children, top = false, bottom = false }) {
    const { token: theme } = useToken()

    return (
        <div style={{
            padding: 15,
            borderRadius: theme.borderRadius,
            backgroundColor: "rgba(255,255,255,0.8)",
            borderTop: top == true ? "2px groove " + theme.colorInfo + "7f" : null,
            borderBottom: bottom == true ? "2px groove " + theme.colorInfo + "7f" : null
        }}>
            {children}
        </div>
    )
}

export function DiventryTitle({ children, size = 15 }) {
    const { token: theme } = useToken()
    return (
        <div style={{
            borderRadius: theme.borderRadius,
            fontWeight: 800,
            color: "rgba(48,48,48, 0.65)",
            fontSize: size,
            padding: 10,
            fontFamily: "Montserrat",
            background: "rgba(231,231,231,0.5)",
        }}>
            {children}
        </div>
    )
}


export function DiventrySide(props) {
    const {
        token: theme
    } = useToken()

    // theme.borderRadius
    return (<>
        {/* Second top menu */}

        <div style={{
            padding: 10,
            margin: "0 auto",
            width: "100%",
            background: props.card === true ? theme.Tabs.cardBg : "rgba(246,246,244,0.6)",
            borderRadius: props.card === true ?
                `0px ${theme.borderRadius}px ${theme.borderRadius}px ${theme.borderRadius}px`
                :
                theme.borderRadius
        }}>

            <Row gutter={[8, 8]}>
                <Col span={12 - 8} style={{
                    // padding: 5,
                    background: "RGBA(246,246,244,0.2)",
                    borderRight: `1px groove rgba(0,0,0,0.3)`,
                    // borderRadius: theme.borderRadius,
                }}>
                    <Menu
                        style={{ width: "100%", background: "initial", border: 0 }}
                        mode="vertical"
                        items={props.menu || []}
                        selectedKeys={props.selectedKeys}
                        onClick={props.onClick}
                    />
                </Col>
                <Col span={12 + 8} style={{
                    paddingTop: 5,
                    paddingRight: 5,
                    paddingBottom: 5,
                    paddingLeft: 5
                }}>
                    {props.children}
                </Col>
            </Row>

        </div>


    </>
    );
}

export function DiventryLayout({ children, color = "#7eb022" }) {
    const [loaded, setLoaded] = useState(true);
    const { token: theme } = useToken()
    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: {
                        cardBg: "rgba(255,255,255,0.8)",
                        horizontalMargin: 0
                    }
                },
                token: {
                    colorPrimary: color,
                    colorInfo: color,

                    // fontSize: 15,
                    // borderRadius: 4
                },
            }}
        >
            <div style={{
                // background: "red",
                height: "100vh"
            }}>
                <div style={{
                    // backgroundImage: `url("/wall/${bg}")`,
                    background: "linear-gradient(90deg, rgba(232,232,236,1) 0%, rgba(125,125,125,1) 50%, rgba(234,241,242,1) 100%)",
                    height: "100%",
                    position: "relative",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    overflowY: "scroll"
                }}>
                    {children}
                </div>
            </div>
        </ConfigProvider>
    );
}


export function DiventryLayoutDohzel({ children }) {
    return (<DiventryLayout children={children} />)
}

export function DiventryLayoutThreatDB({ children }) {
    return (<DiventryLayout children={children} />)
}

export function DiventryLayoutCyberIntel({ children }) {
    return (<DiventryLayout children={children} />)
}

// export function DiventryLayoutThreatDB({ children }) {
//     return (<DiventryLayout children={children} color="#a24732" />)
// }

// export function DiventryLayoutCyberIntel({ children }) {
//     return (<DiventryLayout children={children} color="#cc6540" />)
// }

// a24732
