
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
    Button,
    Modal,
    Space,
    Spin,
    Table,
    Tabs,
    Form,
    Input,
    Select,
    Tag,
    notification,
    theme,
    Popconfirm
} from 'antd';

import {
    ReloadOutlined,
    LockOutlined
} from '@ant-design/icons';

import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../../Templates/Layout'

import {
    ThreatDBTag
} from '../../../Templates/Tag'

const { useToken } = theme;


export function Review({ kernel }) {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()

    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)

    const [profile, setProfile] = useState({})
    const [Review, setReview] = useState([])


    const [form] = Form.useForm()
    const [addModal, setAddModal] = useState(false)

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            // render: (text, record, index) => {
            //     return (<Brands.BrandedDevice device={record} />)
            // }
        },
        {
            title: 'Domain / IP',
            dataIndex: 'domain',
            key: 'domain',
            filterSearch: (value, record) => new RegExp(value).test(record.domain),
            render: (text, record, index) => {
                return (<strong>{text}</strong>)
            }
        },


        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => a.date - b.date,
            render: (text, record, index) => {
                if (!text)
                    return ("-")
                return (<div style={{ fontSize: "0.9em" }}>{new Date(text).toLocaleString()}</div>)
            }
        },


        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, record, index) => {
                return (record.tags.map((tag) => {
                    return (<ThreatDBTag key={`${record.key}-${tag}`} kernel={kernel} tag={tag} />)
                }))

            }
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a, b) => a.count - b.count,
        },
        {
            title: '',
            dataIndex: 'buttons',
            key: 'buttons',
            align: "right"
        },
    ]

    // async load
    useEffect(() => {

        async function fetch() {
            setLoading(true)
            var response = await kernel.api.get(`/dohzel/review/list`)
            if (response.error) {
                notification.error({
                    message: `Error getting device list`,
                    description: response.error
                })
                return
            }

            for (let data of response.data) {
                // process button
                data.buttons = <Space.Compact size="small">
                    <Button onClick={() => {
                        form.setFieldsValue({
                            id: data.id,
                            domain: data.domain,
                            detectionTags: data.tags,
                            counterTags: []
                        })
                        setAddModal(true)
                    }}>Correction</Button>

                    <Popconfirm
                        placement="left"
                        title="Confirm the blocking"
                        description={<>Do you confirm that the domain <strong>{data.domain}</strong> deserves to be blocked according to the displayed tags?</>}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={async () => {
                            const ret = await kernel.api.post(
                                `/dohzel/review/confirm`,
                                { id: data.id }
                            )
                            if (ret.error) {
                                notification.error({
                                    message: ret.error
                                })
                                return
                            }

                            notification.success({
                                message: "The domain/IP has been confirmed, it will no longer appear in this list with the same tags"
                            })

                            setLoaded(!loaded)
                        }}
                    >
                        <Button >Confirm</Button>
                    </Popconfirm>
                </Space.Compact>
            }


            setTimeout(() => {
                setLoading(false)
                setReview(response.data)
            }, 500)
        }

        fetch()
    }, [loaded])

    async function handleUpdateOk() {
        const values = form.getFieldsValue()

        const payloadCR = {
            id: values.id,
            tags: values.counterTags
        }
        const ret = await kernel.api.post(`/dohzel/review/correct`, payloadCR)
        if (!ret || ret.error) {
            notification.error({
                message: 'Can not make the correction',
                description: ret.error
            })
            return
        }
        else {
            notification.success({
                message: 'Correction recorded'
            })
        }

        // add counter measure
        if (kernel.config.counterMeasureList) {
            const payloadCL = [{
                domain: values.domain,
                tags: values.counterTags,
                type: 1
            }]

            const ret = await kernel.api.post(`/threatdb/list/insert/${kernel.config.counterMeasureList}`, payloadCL)
            if (!ret || ret.error) {
                notification.error({
                    message: 'Can not add counter measure',
                    description: ret.error
                })
            }
            else {
                notification.success({
                    message: 'Counter measure has been added'
                })
            }
        }

        setAddModal(false)
        setLoaded(!loaded)
    }

    async function handleUpdateCancel() {
        setAddModal(false)
    }

    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="DoHzel Review"
                description="View the domains / IPs currently blocked by tags"
            />


            <Modal title="Correction of a Detection Entry" open={addModal} onOk={handleUpdateOk} onCancel={handleUpdateCancel}>
                <Spin spinning={loading}>
                    <Form
                        // layout="vertical"
                        form={form}
                    >

                        <div>
                            You can correct the tags for this entry. Remember, to reverse a tag, you can redefine it by adding "!" at the beginning. For example, if "ads" is unnecessary, you can negate it with "!ads".
                            The changes made here will be stored in the countermeasures list, and the entry will be removed from this list.
                        </div>
                        <hr size={1} />

                        <Form.Item label="Information ID" name="id">
                            <Input disabled={true} />
                        </Form.Item>

                        <Form.Item label="Domain Name" name="domain">
                            <Input disabled={true} />
                        </Form.Item>

                        <Form.Item label="Detection Tags" name="detectionTags">
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                disabled={true}
                            />
                        </Form.Item>

                        <Form.Item label="Counter Tags" name="counterTags">
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>

            <DiventryLayoutContent>
                <DiventryBlock bottom={true}>
                    <Space>
                        <Button onClick={() => {
                            setLoaded(!loaded)
                        }}>
                            Reload
                        </Button>
                    </Space>
                </DiventryBlock>
                <DiventryBlock>
                    <Table
                        // bordered={true}
                        // pagination={{ position: ['topLeft', 'bottomRight'] }}
                        ellipsis={true}
                        loading={loading}
                        showSizeChanger={true}
                        dataSource={Review}
                        columns={columns}
                        pagination={{ defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['50', '100', '200'] }}
                    />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />

        </DiventryLayoutThreatDB>
    )
}
