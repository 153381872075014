
import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"
import { Content } from '../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber,
    ColorPicker
} from 'antd'

import {
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons'


import { DiventryMenu } from '../../Templates/Menu'
import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventryTitle
} from '../../Templates/Layout'

const columns = [
    {
        title: 'URL',
        dataIndex: 'link',
        key: '_id'
    },
    {
        title: 'Fetching mode',
        dataIndex: 'type',
        // key: 'type',
    },
    {
        title: 'Tags',
        dataIndex: 'forcingTags',
        // key: 'forcingTags',
    },
    {
        title: '',
        dataIndex: 'buttons',
        // key: 'buttons',
        align: "right"
    },
]

export function Search({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [document, setDocument] = useState({ found: false })
    const [search, setSearch] = useState("")

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const search = searchParams.get('query')
        setSearch(search)
        async function fetch() {
            const newTable = []
            const raw = await kernel.api.get(`/threatdb/ioc/lookup?query=${search}`)
            if (!raw || raw.error) {
                notification.error({
                    message: 'Can not run search',
                    description: raw.error
                })
                return
            }
            setDocument(raw.data.result)
            console.log(raw.data.result)
        }
        // fetch()
    }, [loaded, searchParams])


    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="ThreatDB Search"
                description="Lookup ThreatDB database"
            />
            <DiventryLayoutContent>

                <DiventryBlock bottom={true}>
                    <Space.Compact>
                        <Input
                            placeholder='IP, network or domain'
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                        />
                        <Button type="primary" onClick={() => {
                            navigate(`/threatdb/search?query=${search}`)
                        }}>
                            Search
                        </Button>
                    </Space.Compact>
                </DiventryBlock>


                {/* <DiventryBlock>
                    <center>
                        No result found
                    </center>
                </DiventryBlock> */}

                <DiventryBlock bottom={true}>
                    <DiventryTitle>Exact match</DiventryTitle>
                    <Descriptions bordered>
                        <Descriptions.Item span={4} label="Query">vfsvfds</Descriptions.Item>
                        <Descriptions.Item span={4} label="Global Tags">cvuvgfds</Descriptions.Item>
                        <Descriptions.Item span={4} label="Retention">cvuvgfds</Descriptions.Item>
                    </Descriptions>
                </DiventryBlock>

                <DiventryBlock bottom={true}>
                    <DiventryTitle>Partial match</DiventryTitle>
                    <Descriptions bordered>
                        <Descriptions.Item span={4} label="Query">vfsvfds</Descriptions.Item>
                        <Descriptions.Item span={4} label="Global Tags">cvuvgfds</Descriptions.Item>
                        <Descriptions.Item span={4} label="Retention">cvuvgfds</Descriptions.Item>
                    </Descriptions>
                </DiventryBlock>

                <DiventryBlock >
                    <DiventryTitle>List result</DiventryTitle>
                    <Table
                        dataSource={[]}
                        columns={columns}
                    />
                </DiventryBlock>



            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>
        </DiventryLayoutThreatDB>

    );
}