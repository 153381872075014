import { useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"

import {
    Button,
    Select,
    Form,
    Input,
    Col,
    Space,
    Spin,
    theme,
    notification
} from 'antd'

import { DiventryLayoutSimple } from '../../Templates/LayoutSimple'

const item = {
    wrapperCol: { span: 16, offset: 8 },
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

export function Forgot({ kernel }) {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const onFinish = async (values) => {
        setLoading(true)
        const response = await kernel.api.post('/user/forget', values)
        if (response.error) {
            notification.error({
                description: <>{response.error}</>
            })
            setLoading(false)
            return
        }
        notification.success({
            message: response.data
        })
        setLoading(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <DiventryLayoutSimple>
            <Spin tip="Loading..." spinning={loading}>
                <Form
                    name="basic"
                    {...layout}
                    style={{
                        // maxWidth: 600,
                    }}
                    initialValues={{
                        // remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                    <Col span={16} offset={8}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Reset password
                            </Button>
                            <Button onClick={() => navigate("/session/login")}>
                                Back
                            </Button>
                        </Space>

                    </Col>
                </Form>
            </Spin>
        </DiventryLayoutSimple>
    )
}
