import React from 'react';
import { Form, Input, Button, notification, Descriptions, Row, Col, Space, Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import {
    DiventryBlock
} from './Layout'
import { UtilsInputLang } from './Utils';

const PasswordReset = ({ kernel }) => {
  const [form] = Form.useForm();

  const validatePassword = (_, value) => {
    if (!value || value.length < 6) {
      return Promise.reject(new Error('At least 6 characters'));
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The two passwords do not match'));
    }
  });

  const onFinish = async (form) => {
        var response = await kernel.api.post("/user/update", form)
        if (response.error) {
            notification.error({
                message: `Error updating account`,
                description: <>{response.error}</>,
                placement: "topRight",
            })

            return
        }

        notification.success({
            message: `Password`,
            description: "Password Updated",
            placement: "topRight",
        })
    }

  return (
  <Form
      form={form}
      name="password_reset"
      onFinish={onFinish}
      layout="vertical"
      initialValues={{ remember: true }}
  >
            <DiventryBlock bottom={true}>
                <h2>Password</h2>

                <Descriptions bordered>
                    <Descriptions.Item label="Old">
                        <Form.Item noStyle
                    name="oldPassword"
                    label=""
                    rules={[
                      { required: true, message: 'Your old password' },          
                      { validator: validatePassword }
                    ]}
                  >
                    {/* <Tooltip trigger={['focus']} title={'Your old password at least 6 characters'} placement="topLeft" overlayClassName="numeric-input"> */}
                      <Input.Password prefix={<LockOutlined />} />
                    {/* </Tooltip> */}
                  </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item label="New">
                        <Form.Item noStyle
                    name="newPassword"
                    label=""
                    rules={[
                      { required: true, message: 'Your new password' },
                      { validator: validatePassword }
                    ]}
                  >
                    <Input.Password prefix={<LockOutlined />} />
                  </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item label="Verification">
                        <Form.Item noStyle
                    name="confirmPassword"
                    label=""
                    dependencies={['newPassword']}
                    rules={[
                      { required: true, message: 'Your new password' },
                      validateConfirmPassword
                    ]}
                  >
                    <Input.Password prefix={<LockOutlined />} />
                  </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
            </DiventryBlock>

            <DiventryBlock>
                <Space >
                    <Form.Item>
        <Button type="primary" htmlType="submit">
          Reset Password
        </Button>
                </Form.Item>
                </Space>
            </DiventryBlock>

        </Form >
  );
};

export default PasswordReset;
