import { useEffect, useRef, useState } from "react"

import {
    Button,
    Select,
    Radio,
    Tabs,
    Input,
    Typography,
    Descriptions,
    Table,
    Form,
    theme,
    Divider,
    Alert,
    notification,
    Space,
    Popconfirm,
    Tooltip
} from 'antd';

import { UtilsInputCountry } from "../../Templates/Utils"

import {
    DiventryBlock
} from '../../Templates/Layout'


export function Billing({ onUpdate, values, kernel }) {
    const formRef = useRef()

    const [type, setType] = useState(values.type)
    useEffect(() => {
        formRef.current.setFieldsValue(values);
    }, [values]);

    useEffect(() => {
        setType(values.type)
    }, [values.type]);

    return (
        <>
            <Form
                ref={formRef}
                name="complex-form"
                onFinish={onUpdate}
            // values={fakeProp}
            >
                <DiventryBlock bottom={true}>
                    <Descriptions column={{ xs: 1, sm: 1, md: 2 }} bordered>
                        <Descriptions.Item label="Type">
                            <Form.Item name='type' noStyle>
                                <Radio.Group
                                    onChange={(e) => {
                                        setType(e.target.value)
                                    }}
                                    style={{textAlign: 'center'}}
                                    options={[
                                        {
                                            label: 'Enterprise',
                                            value: 'enterprise',
                                            style: {borderRadius: 5, marginTop: 5, textAlign: 'center'}
                                        },
                                        {
                                            label: 'Private',
                                            value: 'private',
                                            style: {borderRadius: 5, marginTop: 5, marginLeft: 5, textAlign: 'center', borderLeft: '1px solid #d9d9d9', contain: 'paint'}
                                        },
                                    ]}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Form.Item>
                        </Descriptions.Item>

                        {type === "enterprise" ?
                            <Descriptions.Item label="Company">
                                <Form.Item name='company' noStyle>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            : null}


                        <Descriptions.Item label="Address">
                            <Form.Item name='address' noStyle>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item label="ZIP/NPA">
                            <Form.Item name='zip' noStyle>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item label="Locality">
                            <Form.Item name='locality' noStyle>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item label="Country">
                            <Form.Item name='country' noStyle>
                                <UtilsInputCountry style={{ width: '100%' }} />
                            </Form.Item>
                        </Descriptions.Item>

                        {type === "enterprise" ? <>
                            <Descriptions.Item label="Registery n°">
                                <Form.Item name='registry' noStyle>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="VAT number">
                                <Form.Item name='vat' noStyle>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                        </> : null}

                    </Descriptions>
                </DiventryBlock>

                <DiventryBlock>
                    <Space >
                        <Button type="primary" htmlType="submit">Save</Button>
                        <Button>Reset</Button>
                    </Space>
                </DiventryBlock>
            </Form>
        </>
    )
}
