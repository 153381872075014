
import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate, useParams } from "react-router-dom"

import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber
} from 'antd'

import {
    CaretLeftOutlined,
    RightOutlined,
    LeftOutlined,
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons'

import {
    DiventryLayoutCyberIntel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventryTitle
} from '../../../../Templates/Layout'

const processors = {
    // // create
    // created: (record) => {
    //     return (<>New {record.type} entry <strong>{record.name}</strong></>)
    // },
    // updated: (record) => {
    //     return (<>{record.type.ucFirst()} <strong>{record.name}</strong> has been updated with tags {Object.keys(record.tags).join(",")}</>)
    // },
    // deleted: (record) => {
    //     return (<>Deleting {record.type} <strong>{record.name}</strong></>)
    // },
}

const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record, index) => {
            return (new Date(text).toLocaleString())
        }
    },
    {
        title: 'Information',
        dataIndex: 'info',
        key: 'info',
        render: (text, record, index) => {
            if (processors[record.msg]) {
                const str = processors[record.msg](record)
                return (str)
            }

            const show = { ...record }
            delete show.date
            delete show.key
            delete show.list
            var dtf = []
            
            for (var k in show.data) {
                dtf.push(`${k}=${show.data[k]}`)
            }
            return (show.type+" "+dtf.join(" "))
        }
    },
    {
        title: 'ID',
        dataIndex: 'id',
        align: "right"
    }
]

export function Logs({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [logs, setLogs] = useState({ data: [] })

    // for navigation
    const navigate = useNavigate()
    const { id } = useParams()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        async function fetch() {
            // const newTable = []
            const next = searchParams.get('next')
            const params = []
            if (next)
                params.push(`gte=${next}`)

            const raw = await kernel.api.get(`/cyberintel/instances/logs/${id}?${params.join("&")}`)
            if (raw.error || !raw.data) {
                notification.error({
                    message: 'Error getting API',
                    description: raw.error
                })
            }
            else
                setLogs(raw.data)
        }
        fetch()
    }, [loaded, searchParams])

    return (

        <>
            <DiventryBlock>
                <Space.Compact>
                    <Button onClick={() => {
                        navigate(`/cyberintel/instances/${id}/logs`)
                        setLoaded(!loaded)
                    }}>
                        Go first
                    </Button>

                    <Button type="primary" disabled={!document.prevCursor ? true : false} onClick={() => {
                        const params = []
                        params.push(`next=${document.prevCursor}`)
                        navigate(`/cyberintel/instances/${id}/logs?${params.join("&")}`)
                        setLoaded(!loaded)
                    }}>
                        <LeftOutlined /> Prev
                    </Button>

                    <Button type="primary" disabled={!document.nextCursor ? true : false} onClick={() => {
                        const params = []
                        params.push(`next=${document.nextCursor}`)
                        navigate(`/cyberintel/instances/${id}/logs?${params.join("&")}`)
                        setLoaded(!loaded)
                    }}>
                        Next <RightOutlined />
                    </Button>
                </Space.Compact>
            </DiventryBlock>
            <DiventryBlock>
                <Table
                    size="small"
                    pagination={false}
                    dataSource={logs.data}
                    columns={columns} />
            </DiventryBlock>
        </>

    )
}