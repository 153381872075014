import { useParams, Link  } from "react-router-dom";
import {
    Spin,
    Col, 
    Row
} from 'antd';

import { DiventryLayoutSimple } from '../Templates/LayoutSimple'

const item = {
    wrapperCol: { span: 16, offset: 8 },
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}


export default function MobilePage() {
    return (
        <DiventryLayoutSimple>

            admin console is not accessible from your smartphone.
            <br/>
            Please use your laptop or tablet
            <br/><br/>

            You can also download our mobile app
            <br/>
            <br/>
            <Row>
                <Col span={12}>
                    <a href="https://play.google.com/store/apps/details?id=com.diventry.dohzelav&hl=fr">
                        <img width={100} src="https://c.clc2l.com/t/g/o/google-playstore-Iauj7q.png"/>
                    </a>
                </Col>
                <Col span={12}>
                    <a onClick={() => {
                        alert('Comming soon');
                    }}>
                        <img width={100} src="https://upload.wikimedia.org/wikipedia/fr/0/09/Logo_App_Store_d%27Apple.png"/>
                    </a>
                </Col>
            </Row>

        </DiventryLayoutSimple>
    );
}