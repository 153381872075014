import { kernel } from '../App'
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from 'react'
import { Tag } from 'antd'
import logoSmall from '../Images/logoSmall.png'
import { Transition } from '../App'

import { CaretLeftFilled } from '@ant-design/icons';

import { Logo } from './index'


const CSSlogo = {
    // fontFamily: "Monteserrat",
    fontWeight: 800,
    color: "#B7CED9"
}


export function Content({
    back, backTransition,
    right,
    topAlign,
    children
}) {
    const [loaded, setLoaded] = useState(true)
    const [top, setTop] = useState(null)
    const [upgrading, setUpgrading] = useState(false)

    const navigate = useNavigate()

    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const content = useRef()

    useEffect(() => {

        function check() {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
            if (!content.current) return
            const usable = window.innerHeight
            const diff = usable - content.current.offsetHeight
            if (diff > 0)
                setTop(diff / 2)
            else
                setTop(40)

            setTimeout(check, 5000)
        }


        if (topAlign === true) {
            setTop(40)
        }
        else {
            setTimeout(check, 100)
        }
    }, [content.current])


    return (
        <>
            <div style={{
                zIndex: 11,
                position: "fixed",
                left: 0,
                top: 0,
                maxWidth: size.width,
                width: "100%",
                // backgroundColor: "red",
                backgroundColor: "rgba(79, 16, 0, 1)",
                textAlign: "center",
                paddingTop: 5,
                paddingBottom: 5,
                fontSize: "1.5em",
                // height: "40px"
                // background: "linear-gradient(0deg, #00405F, #004C72)"
            }}>
                <table width="100%" height="30px">
                    <tr>
                        <td width="35%" style={{ color: "white", textAlign: "left", paddingLeft: "5px" }}>

                            {back ?
                                <div style={{ cursor: "pointer" }} onClick={() => {
                                    if (!backTransition)
                                        navigate(back)
                                    else {
                                        Transition(['#page'], () => navigate(back), backTransition)
                                    }
                                }}>
                                    <CaretLeftFilled />
                                </div>
                                : null}
                        </td>
                        <td width="30%" style={{ fontSize: "0.6em", verticalAlign: "middle" }}>
                            <div style={{ ...CSSlogo }}>
                                ThreatDB
                                <span className="tag-by" style={{ position: "absolute", fontSize: "0.5em", marginLeft: "5px", top: 10 }}>
                                    by HAFNOVA
                                </span>
                  
                            </div>
                        </td>
                        <td width="45%" style={{
                            color: "white",
                            fontSize: "0.5em",
                            textAlign: "right",
                            paddingRight: 10,
                        }}>
                            {right}
                        </td>
                    </tr>
                </table>
            </div>

            <div ref={content} style={{
                margin: "0 auto",
                zIndex: 10,
                maxWidth: 1440,
                // maxWidth: "1024px",
                padding: 5,
                paddingTop: 50,
                // paddingBottom: 75,
                color: "#CCCBCC",
                fontWeight: 300,
                // opacity: top ? 1 : 0,
                // backgroundColor: "red",
                // textAlign: "center"
            }}>
                {children}
            </div>

            <div style={{
                zIndex: 11,
                position: "fixed",
                left: 0,
                bottom: 0,
                width: "100%",
                textAlign: "center"
            }}>
                <div style={{
                    color: "#E5F2F8",
                    paddingTop: 20,
                    paddingBottom: 10,
                    fontWeight: 300,
                    fontSize: "0.6em",
                    textTransform: "uppercase",
                    // background: "linear-gradient(180deg, #00405F, #004C72)"
                    // background: "white"
                }}>
                    <strong>ThreatDB</strong> &copy; Hafnova 2024
                </div>
            </div>
        </>
    );
}