import { useEffect, useState } from "react"

import {
    Button,
    Input,
    Table,
    Select,
    notification,
    Modal,
    Tag,
    Popconfirm,
    Tooltip,
    Form
} from 'antd'

import { CheckCircleTwoTone, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import {
    DiventryBlock
} from '../../Templates/Layout'

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record, index) => {
            return (<strong>{text}</strong>)
        }
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a, b) => a.createdAt - b.createdAt,
        render: (text, record, index) => {
            return (<div style={{ fontSize: 10 }}>{new Date(text).toLocaleString()}</div>)
        }
    },
    {
        title: 'Last Access',
        dataIndex: 'lastAccess',
        key: 'lastAccess',
        sorter: (a, b) => a.createdAt - b.createdAt,
        render: (text, record, index) => {
            return (<div style={{ fontSize: 10 }}>{text ? new Date(text).toLocaleString() : "-"}</div>)
        }
    },
    {
        title: 'Permissions',
        dataIndex: 'permissionsTag',
        key: 'permissions',

    },
    {
        title: 'API Key',
        dataIndex: 'apiKey',
        key: 'apiKey',
    },
    {
        // title: 'Status',
        dataIndex: 'actions',
        key: 'actions',
        align: "right",
    },
]


export function API({ onUpdate, values, kernel }) {
    const [loaded, setLoaded] = useState(true)

    const [permissionsList, setPermissionsList] = useState({})
    const [list, setList] = useState([])

    const [isModalCreate, setIsModalCreate] = useState(false)
    const [createForm] = Form.useForm()

    useEffect(() => {
        async function fetchPermissions() {
            var response = await kernel.api.get("/user/api/permissions")
            if (!response || response.error) {
                notification.error({
                    message: `Can not get permissions list`,
                    description: response?.error || null
                })
                return
            }
            setPermissionsList(response.data)
            fetchList(response.data)
        }

        async function fetchList(permissions) {
            var response = await kernel.api.get("/user/api/list")
            if (!response || response.error) {
                notification.error({
                    message: `Can not list API keys`,
                    description: response?.error || null
                })
                return
            }
            for (let item of response.data) {
                item.permissionsTag = item.permissions.map((item) => <Tag>{permissions[item]}</Tag>)
                item.actions = <Popconfirm
                    placement="left"
                    title="Delete this API key ?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={async () => {
                        const ret = await kernel.api.post(`/user/api/remove`, { id: item.id })
                        if (ret.error) {
                            notification.error({
                                message: 'Error removing API key',
                                description: ret.error
                            })
                            return
                        }
                        notification.success({
                            message: 'API Key deleted'
                        })
                        setLoaded(!loaded)
                    }}
                >
                    <Button>Delete</Button>
                </Popconfirm>
            }
            setList(response.data)
        }

        fetchPermissions()
    }, [loaded])

    // Create handler
    const handleCreateOk = async () => {
        const data = {
            ...createForm.getFieldsValue()
        }
        console.log(data)
        const ret = await kernel.api.post(`/user/api/create`, data)
        if (ret.error) {
            notification.error({
                message: 'Error creating API keys',
                description: ret.fields ? Object.values(ret.fields).map((el) => <div key={el}>{el}</div>) : ret.error
            })
            return
        }
        notification.success({
            message: 'New API keys created'
        })
        setIsModalCreate(false)
        setLoaded(!loaded)
    }

    const handleCreateCancel = () => {
        setIsModalCreate(false)
    }

    return (
        <>
            <Modal
                title="Create API keys"
                open={isModalCreate}
                onOk={handleCreateOk}
                onCancel={handleCreateCancel}
            >
                <Form
                    layout="vertical"
                    form={createForm}
                >
                    <Form.Item label="API keys name" name="name">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Permissions" name="permissions">
                        <Select
                            mode="multiple"
                            // style={{ width: '100%' }}
                            placeholder="Please select permissions"
                            defaultValue={[]}
                            //   onChange={handleChange}
                            options={Object.keys(permissionsList).map((key) => {
                                return ({
                                    value: key,
                                    label: permissionsList[key]
                                })
                            })}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <DiventryBlock bottom={true}>
                <Button onClick={() => {
                    createForm.resetFields()
                    setIsModalCreate(true)
                }}
                    type="primary"
                    icon={<PlusOutlined />}
                >
                    Create API keys
                </Button>
            </DiventryBlock>

            <DiventryBlock>
                <Table size="small" dataSource={list} columns={columns} pagination={false} />
            </DiventryBlock>
        </>
    )
}
