import { useEffect, useState } from 'react'

import {
    Tag,
    Tooltip,
} from 'antd'

import {
    DiffFilled
} from '@ant-design/icons'

const thisIsAllowed = "This is an allow reverse tag. Tags marked with this content will be considered as allowed."

export function ThreatDBTag({ kernel, tag }) {
    const ktag = tag.substring(1)
    return (<>
        {tag[0] === "!" ?
            kernel?.tags?.list[ktag] ?
                <Tooltip placement="left" title={thisIsAllowed}>
                    <Tag color={kernel.tags.list[ktag].color}><DiffFilled /> {tag}</Tag>
                </Tooltip>
                :
                <Tooltip placement="left" title={thisIsAllowed}>
                    <Tag><DiffFilled /> {tag}</Tag>
                </Tooltip>
            :
            kernel?.tags?.list[tag] ?
                <Tag color={kernel.tags.list[tag].color}>{tag}</Tag>
                :
                <Tag>{tag}</Tag>
        }

    </>)
}