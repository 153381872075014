import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Statistic from "../../../../Templates/Statistic"

import {
    Card,
    Flex,
    Select,
    Row,
    Col
} from 'antd'

import {
    DiventryBlock
} from '../../../../Templates/Layout'

HC_exporting(Highcharts);

const periodes = [
    { value: 'last24h', label: 'last 24 hours', global: 'total24h' },
    { value: 'last30d', label: 'last 30 days', global: 'total30d' },
    { value: 'last90d', label: 'last 90 days', global: 'total90d' },
]

const initialGlobaux = [
    {
        name: 'requests',
        value: 0,
        color: '#000000'
    },
    {
        name: 'blocked requests',
        value: 0,
        color: '#ff0000cf'
    },
    {
        name: 'of blocked requests',
        value: 0,
        color: '#777777',
        unit: '%'
    }
];

const DeviceTypes = {
    appleMac: "Apple MacOS",
    appleiOS: "Apple iOS",
    googleAndroid: "Google Android",
    windows: "Windows",
    linux: "Linux",
    freeBSD: "FreeBSD",
    proxy: "DoHzel Proxy",
    opnSense: "OPNsense",
    fritz: "FritZ! Box",
    browser: "Browser"
}

const statColors = {
    all: '#3498db',
    blocking: "#e74c3c",
    detecting: '#f1c40f',
    passing: '#16a085'
}

export function Statistics({ kernel }) {

    const { id } = useParams();

    const [devices, setDevices] = useState([
        { value: 'all', label: 'All devices' }
    ])

    const [globalStats, setGlobalStats] = useState(initialGlobaux);

    const [filters, setFilters] = useState({
        device: "all",
        periode: 'last30d'
    })

    const [tags, setTags] = useState([]);

    // Fetch tags
    useEffect(() => {
        async function fetchTags() {
            const raw = await kernel.api.get('/threatdb/tags/list')
            if (raw.error) {
                return
            }  
            console.log(raw?.data?.map((item) => ({name: item?.name, color: item?.color})))
            setTags(raw?.data?.map((item) => ({name: item?.name, color: item?.color}))) 
        }

        fetchTags()
    }, [id])


    useEffect(() => {
        asyncFetch();
    }, [id]);

    useEffect(() => {
        (async () => {
            var response = await kernel.api.get(`/dohzel/device/list?profileId=${id}`)
            if (response.error) {
                return
            }
            setDevices([
                { value: 'all', label: 'All devices' },
                ...response.data?.map(device => ({ value: device.id, label: device?.name ?? (`${DeviceTypes?.[device.deviceType] ?? ''} ${`v${device?.device?.browser?.os?.version?.trim() ?? ''} `}${device?.device?.browser?.device?.model ? `on ${device?.device?.browser?.device?.model ?? ''}` : ''}`) }))
            ])
        })()
    }, [id]);

    const asyncFetch = (periode = filters?.periode) => {
        (async () => {
            const global = periodes.find((period) => period.value == periode)?.global;
            const globaux = await kernel.api.get(`/dohzel/profile/stats/${global}?id=${id}`)
            if (globaux.error) {
                console.log(globaux.error)
            }

            const stats = await kernel.api.get(`/dohzel/profile/stats/${periode ?? filters?.periode}?id=${id}`)
            if (stats.error) {
                console.log(stats.error)
            }

            handleGlobalStats(globaux);
        })()
    };

    const handleGlobalStats = (globaux) => {
        if (globaux?.data) {
            const _globalStats = [...initialGlobaux];
            _globalStats[0].value = globaux?.data?.values?.reduce((acc, item) => acc + item?.[1], 0) ?? 0;
            _globalStats[1].value = globaux?.data?.values?.reduce((acc, item) => acc + item?.[2], 0) ?? 0;
            _globalStats[2].value = ((globaux?.data?.values?.reduce((acc, item) => acc + item?.[2], 0) ?? 0) / (globaux?.data?.values?.reduce((acc, item) => acc + item?.[1], 0) ?? 1) * 100).toFixed(2) ?? 0;
            setGlobalStats(_globalStats)
        }
        else {
            setGlobalStats([])
        }
    }


    return (
        <>
            <DiventryBlock>
                <Flex justify={'space-between'} align={'flex-start'} style={styles.filters}>
                    <Select
                        value={filters.device}
                        style={{ minWidth: 200 }}
                        onChange={(key) => {
                            setFilters({ ...filters, device: key })
                        }}
                        disabled={true}
                        options={devices}
                    />

                    <Select
                        value={filters.periode}
                        style={{ minWidth: 200 }}
                        onChange={(key) => {
                            setFilters({ ...filters, periode: key });
                            asyncFetch(key);
                        }}
                        options={periodes}
                    />
                </Flex>

                {
                    globalStats?.length ?
                        <Card gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {
                                globalStats.map(stat => (
                                    <Card.Grid style={{ width: `${(100 / globalStats?.length) - 2}%`, margin: '1%' }}>
                                        <h1 style={{ ...styles.statName, color: stat.color }}>{stat.value} {stat.unit}</h1>
                                        <span style={styles.statValue}>{stat.name}</span>
                                    </Card.Grid>
                                ))
                            }
                        </Card>
                        : ''
                }
                <Row>
                    <Col span={16}>
                        <Card style={{ ...styles.statContainer, textAlign: 'left' }}>
                            {
                                <Statistic
                                    kernel={kernel}
                                    endpoint={`/dohzel/profile/stats/${filters?.periode}?id=${id}`}
                                    type='area'
                                    statTypes={{
                                        all: '#3498db',
                                        blocking: "#e74c3c",
                                        bypassing: 'purple',
                                        detecting: '#f1c40f',
                                        passing: '#16a085'
                                    }}
                                    disabled={['passing']}
                                    exclude={['bypassing']}
                                />
                            }
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ ...styles.pieContainer, textAlign: 'left' }}>
                            {
                                <Statistic
                                    kernel={kernel}
                                    endpoint={`/dohzel/profile/tags/${periodes.find((period) => period.value == filters.periode)?.global}?id=${id}`}
                                    statCategoryName={'tags'}
                                    statName={'Tag'}
                                    type='pie'
                                />
                            }
                        </Card>
                    </Col>
                </Row>



            </DiventryBlock>

        </>
    )
}

const styles = {
    filters: {
        marginTop: 20
    },
    statContainer: {
        marginTop: 25,
        textAlign: 'center'
    },
    pieContainer: {
        marginTop: 25,
        textAlign: 'center'
    },
    statName: {
        marginBottom: '-0.2em',
        fontWeight: 800
    },
    statValue: {
        color: '#777777',
        fontSize: '18px'
    }
}