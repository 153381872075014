import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import {
    Button,
    Space,
    Popconfirm,
    Form,
    message
} from 'antd'

import { DiventryBlock } from '../../../../Templates/Layout'


export function Advanced({ kernel, updateDevice }) {

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();

    const confirm = async (e) => {
        const deletedDevice = await kernel.api.post(`/dohzel/profile/remove`, {
            id
        });
        if (deletedDevice.error) {
            message.error(deletedDevice.error);
            return;
        } 
        message.success('Your profile has been deleted');
        navigate("/dohzel/profile");
    };


    return (
        <>
            <DiventryBlock>
                <Space.Compact>
                <Popconfirm
                    title="Delete the profile"
                    description="Are you are sure to delete the profile, all devices will be excluded ?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger type="primary">Delete profile</Button>
                </Popconfirm>
                </Space.Compact>
            </DiventryBlock>
        </>
    );
}
