import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
    Tag,
    Space,
    Button,
    Input, notification,
    Descriptions,
    Checkbox,
    Dropdown,
    Menu
} from 'antd';
import {
    SearchOutlined,
    LeftOutlined,
    SyncOutlined,
    LockOutlined,
    UnlockOutlined,
    DoubleRightOutlined,
    GlobalOutlined,
    RightOutlined,
    DownOutlined
} from '@ant-design/icons';
import { LogTable } from '../../../Templates/Logs';
import { ShowTag } from "../../ThreatDB";


export const detectionMethods = {
    "dns-request": "DNS request",
    "dns-ip-response": "IP DNS response",
    "ip-output": "IP output",
}

export const LogsColors = {
    blocking: "4px solid #a14838",
    detecting: "4px solid #e58900",
    passing: "4px solid #22d933",
    bypassing: "4px solid #4974a5",
}

export const LogsPolicy = {
    blocking: "Blocked",
    detecting: "Detect only",
    passing: "Accepted",
    bypassing: "Bypassed",
}

export const LogsDescription = [
    {
        label: 'Passed',
        value: 'passing',
    },
    {
        label: 'Detected',
        value: 'detecting',
    },
    {
        label: 'Blocked',
        value: 'blocking'
    },
];

export function LogsStream({ kernel, url, endpoint, logType, profileId, deviceId }) {
    const navigate = useNavigate();
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState({
        search: "",
        policies: ['detecting', 'blocking']
    });
    const [searchParams] = useSearchParams();
    const [logs, setLogs] = useState([]);
    const [document, setDocument] = useState({});
    const [initial, setInitial] = useState(true);
    const [domains, setDomains] = useState([]);
    const [blockDomains, setBlockDomains] = useState([]);
    const [loading, setLoading] = useState(false);
    const [device, setDevice] = useState({});
    const [selectedSize, setSelectedSize] = useState(localStorage?.getItem('sizePager') ?? '100');

    const handleSizeClick = (e) => {
        const selectedLabel = e.key; // Ou e.item.props.children pour obtenir le texte
        console.log(selectedLabel);
        setSelectedSize(selectedLabel);
        const params = doFilter({ gte: undefined, limit: selectedLabel });
        localStorage?.setItem('sizePager', selectedLabel)
        navigate(`${url}?${params.join("&")}`);
    };

    const sizeChangerProps = (
        <Menu onClick={handleSizeClick}>
          <Menu.Item key="50">50 per page</Menu.Item>
          <Menu.Item key="100">100 per page</Menu.Item>
          <Menu.Item key="200">200 per page</Menu.Item>
          <Menu.Item key="300">300 per page</Menu.Item>
          <Menu.Item key="400">400 per page</Menu.Item>
          <Menu.Item key="500">500 per page</Menu.Item>
        </Menu>
    );
    

    function buildFilter(overWrite) {
        const params = [];

        const after = { ...filters, ...overWrite };
        for (var key in after) {
            const value = after[key];
            if (Array.isArray(value) && value.length > 0)
                params.push(`${key}=${value.join(",")}`);
            else if (typeof value === "string" && value.length > 0)
                params.push(`${key}=${value}`);
        }

        return (params);
    }

    useEffect(() => {
        async function fetch() {
            var response = await kernel.api.get(`/dohzel/device/get?id=${deviceId}`)
            if (response.error) {
                notification.error({
                    message: `Error fetching information`,
                    description: response.error
                })

                return
            }
            setDevice(response.data)
        }
        if (logType === 'device') {
            fetch();
        }
    }, [])

    useEffect(() => {
        var filteredEnpoint = { ...filters };

        const gte = searchParams.get('gte');
        if (gte && gte.length > 0) {
            filteredEnpoint.gte = gte;
            setAutoRefresh(false);
        }
        else if (filters.gte)
            delete filteredEnpoint.gte;

        const search = searchParams.get('search');
        if (search && search.length > 0) {
            filteredEnpoint.search = search;
        }
        else if (filters.search)
            delete filteredEnpoint.search;

        const policies = searchParams.get('policies');
        if (policies && policies.length > 0) {
            filteredEnpoint.policies = policies.split(",");
        }
        else if (filters.policies && initial === false)
            delete filteredEnpoint.policies;

        setFilters(filteredEnpoint);
        setInitial(false);
    }, [searchParams, url, endpoint]);

    useEffect(() => {
        setSearch("");
        setFilters({
            search: "",
            policies: ['detecting', 'blocking']
        });
    }, [url, endpoint]);

    useEffect(() => {
        var stop = false;
        var timer = null;
        async function fetch() {
            timer = null;
            if (stop === true) return;

            const raw = await kernel.api.get(`${endpoint}?${buildFilter(filters).join("&")}&limit=${selectedSize}`);
            if (raw.error) {
                notification.error({
                    message: 'Error fetching logs',
                    description: raw ? raw.error : null
                });
                return;
            }

            // force to separe data to document
            console.log(raw.data.data);
            setLogs(raw.data.data);
            delete raw.data.data;
            setDocument(raw.data);

            // auto refresh
            if (autoRefresh === true)
                timer = setTimeout(fetch, 5000);
        }
        fetch();

        return (() => {
            stop = true;
            if (timer)
                clearTimeout(timer);
        })
    }, [filters, autoRefresh]);

    useEffect(() => {
        async function fetch() {
            const ret = await kernel.api.get(`/dohzel/ablist/list?${logType == 'profile' ? `profileId=${profileId}` : (device?.deviceManagement == 'mdm' ? `profileId=${device?.profileId}` : `deviceId=${deviceId}`)}&list=allow`);
            if (!ret || ret.error) {
                return;
            }
            setDomains(ret.data);
        }
        fetch();
    }, [loading, device]);

    useEffect(() => {
        async function fetch() {
            const ret = await kernel.api.get(`/dohzel/ablist/list?${logType == 'profile' ? `profileId=${profileId}` : (device?.deviceManagement == 'mdm' ? `profileId=${device?.profileId}` : `deviceId=${deviceId}`)}&list=block`);
            if (!ret || ret.error) {
                return;
            }
            setBlockDomains(ret.data)
        }
        fetch();
    }, [loading, device]);

    function doFilter(overWrite) {
        const params = [];
        const after = { ...filters, ...overWrite };
        for (var key in after) {
            const value = after[key];
            if (Array.isArray(value) && value.length > 0)
                params.push(`${key}=${value.join(",")}`);
            else if (typeof value === "string" && value.length > 0)
                params.push(`${key}=${value}`);
        }
        return (params);
    }

    const addToList = async (input, type='allow') => {
        setLoading(true);
        const data = {
            list: type,
            [logType == 'profile' ? 'profileId' : (device?.deviceManagement == 'mdm' ? 'profileId' : 'deviceId')]: (logType == 'profile' ? profileId : (device?.deviceManagement == 'mdm' ? device?.profileId : deviceId))
        };

        var masked = input.split("/");
        var detection = "Domain";
        if (/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/.test(masked[0])) {
            detection = "IPv4";
            data.type = "cidr";
            data.cidr = input;
        }
        else if (masked[0].indexOf(":") > 0) {
            detection = "IPv6";
            data.type = "cidr";
            data.cidr = input;
        }
        else {
            data.type = "domain";
            const t = input.split(".");
            if (t[0] === "*") {
                data.domainWildcard = true;
                t.shift();
                data.domain = t.join(".");
            }

            else
                data.domain = input;
        }

        const ret = await kernel.api.post('/dohzel/ablist/create', data)
        if (!ret || ret.error) {
            notification.error({
                message: 'Error adding rule',
                description: ret.error
            });
            return;
        }

        setLoading(false);
    }

    const removeFromList = async (entry, domains) => {
        setLoading(true);
        const domain = domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain);
        const data = { id: domain.id };
        const ret = await kernel.api.post(`/dohzel/ablist/remove`, data);
        if (!ret || ret.error) {
            notification.error({
                message: 'Error deleting entry',
                description: ret.error
            });
            return;
        }
        setLoading(false);
        
    }

    return (<>
        <Space style={{ paddingBottom: 10 }}>
            <Input
                // allowClear
                prefix={<SearchOutlined />}
                style={{ width: 300 }}
                value={search}
                placeholder="Type to filter"
                onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value === '') {
                        return;
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        const params = doFilter({ ...filters, search, limit: selectedSize });
                        navigate(`${url}?${params.join("&")}`);
                        return;
                    }
                }} />

            <Space.Compact>
                {document.prevCursor ?
                    <Button onClick={() => {
                        const params = doFilter({ gte: undefined, limit: selectedSize });
                        navigate(`${url}?${params.join("&")}`);
                    }}>
                        Show last
                    </Button>
                    : null}

                {document.prevCursor ?
                    <Button type="default" onClick={() => {
                        const params = doFilter({ gte: document.prevCursor, limit: selectedSize });
                        navigate(`${url}?${params.join("&")}`);
                    }}>
                        <LeftOutlined /> Prev
                    </Button>
                    : null}

                {document.nextCursor ?
                    <Button type="default" onClick={() => {
                        const params = doFilter({ gte: document.nextCursor, limit: selectedSize });
                        navigate(`${url}?${params.join("&")}`);
                    }}>
                        Next <RightOutlined />
                    </Button>
                    : null}

                {!document.prevCursor ?
                    <Button type="default" onClick={() => {
                        setAutoRefresh(!autoRefresh);
                    }}>
                        <SyncOutlined spin={autoRefresh} />
                    </Button>
                    : null}

            </Space.Compact>

            <Dropdown overlay={sizeChangerProps}>
                <Button>
                    <Space>
                    {selectedSize} per page
                    <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>

            <Checkbox.Group
                options={LogsDescription}
                value={filters.policies}
                onChange={(selected) => {
                    const params = doFilter({ policies: selected, limit: selectedSize });
                    navigate(`${url}?${params.join("&")}`);
                }} />
        </Space>

        <LogTable
            kernel={kernel}
            data={logs.map((entry) => {
                return ({
                    color: LogsColors[entry.policy],
                    left: <>
                        <div style={{
                            fontWeight: 600,
                            fontSize: 17
                        }}>
                            {entry.domain ? entry.domain : entry.ip}
                        </div>
                    </>,
                    right: <>
                        <div>
                            <span style={{ fontWeight: 700, cursor: "pointer" }}>{entry.device} </span>

                            {entry.protocol !== "doh" && entry.protocol !== "dot" ?
                                <>
                                    {entry.forwardingMode === "DNS2DNS" ?
                                        <Tag
                                            color="gold"
                                            bordered={false}
                                            style={{
                                                fontSize: 10,
                                                fontWeight: 700,
                                                cursor: "pointer"
                                            }}
                                        >
                                            <UnlockOutlined /> {entry.protocol.toUpperCase()}<DoubleRightOutlined /> {entry.forwardingMode.toUpperCase()}
                                        </Tag>
                                        :
                                        <Tag
                                            color="lime"
                                            bordered={false}
                                            style={{
                                                fontSize: 10,
                                                fontWeight: 700,
                                                cursor: "pointer"
                                            }}
                                        >
                                            <LockOutlined /> {entry.protocol.toUpperCase()}<DoubleRightOutlined /> {entry.forwardingMode ? entry.forwardingMode.toUpperCase() : "DOH"}
                                        </Tag>
                                    }

                                </> : null}

                            {entry.protocol === "doh" || entry.protocol === "dot" ?
                                <>
                                    <Tag
                                        color="green"
                                        bordered={false}
                                        style={{
                                            fontSize: 10,
                                            fontWeight: 700,
                                            cursor: "pointer"
                                        }}
                                    >
                                        <LockOutlined /> {entry.protocol.toUpperCase()}
                                    </Tag>
                                </>
                                : null}

                        </div>
                        <div style={{
                            fontWeight: 500,
                            fontSize: 10,
                            paddingRight: 10
                        }}>
                            {new Date(entry.date).toLocaleString()}
                        </div>
                    </>,
                    expand: <>
                        <Descriptions size="small">
                            <Descriptions.Item label="Date">
                                {new Date(entry.date).toLocaleString()}
                            </Descriptions.Item>

                            {entry.protocol ?
                                <Descriptions.Item label="Protocol">
                                    {entry.protocol.toUpperCase()}
                                </Descriptions.Item>
                                : null}
                            {entry.forwardingMode ?
                                <Descriptions.Item label="Forwarded Protocol">
                                    {entry.forwardingMode.toUpperCase()}
                                </Descriptions.Item>
                                : null}
                            <Descriptions.Item label="ID">
                                {entry.key}
                            </Descriptions.Item>

                            <Descriptions.Item label="Action">
                                {LogsPolicy[entry.policy]}
                            </Descriptions.Item>
                            <Descriptions.Item label="Detection method">
                                {detectionMethods[entry.mode]}
                            </Descriptions.Item>
                            {entry.recordType ?
                                <Descriptions.Item label="DNS record type">
                                    {entry.recordType}
                                </Descriptions.Item>
                                : null}
                            <Descriptions.Item label="Device">
                                {entry.device}
                            </Descriptions.Item>

                            <Descriptions.Item label="Contact IP">
                                {entry.contactIP}
                            </Descriptions.Item>

                            {entry.forwardedIP ?
                                <Descriptions.Item label="Client IP">
                                    <strong>{entry.forwardedIP}</strong>
                                </Descriptions.Item>
                                : null}

                            {entry.sourceIP ?
                                <Descriptions.Item label="Internal Source IP">
                                    <strong>{entry.sourceIP}</strong>
                                </Descriptions.Item>
                                : null}

                            {entry.forwardingRule && entry.forwarding ?
                                <Descriptions.Item label="Conditionnal Forwarding">
                                     <div>To <strong>{entry.forwarding}</strong> by rule #{entry.forwardingRule}</div>
                                </Descriptions.Item>
                                : null}

                            {entry?.alert?.reasons && entry.alert.reasons.length > 0 ?
                                <Descriptions.Item label="Reasons">
                                    {entry.alert.reasons.map((tag) => {
                                        return (
                                            <ShowTag
                                                key={`log-tr-${entry.id}-${tag}`}
                                                kernel={kernel}
                                                tag={tag} />
                                        );
                                    })}
                                </Descriptions.Item>
                                : null
                            }

                        </Descriptions>
                        <div style={{paddingTop: 10}}>
                            {
                                (entry?.policy == 'blocking'  || entry.policy === 'detecting') && !domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                <Button loading={loading} type="primary" style={{marginRight: 10}} onClick={() => addToList(entry.domain)}>{device?.deviceManagement == 'mdm' ? 'Add to profile allow list' : 'Add to allow list'}</Button>
                            }
                            {
                                (entry?.policy == 'passing'  || entry.policy === 'detecting') && !blockDomains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                <Button loading={loading} type="primary" style={{background: '#a14838'}} onClick={() => addToList(entry.domain, 'block')} >{device?.deviceManagement == 'mdm' ? 'Add to profile bloc klist' : 'Add to block list'}</Button>  
                            }
                            {
                                (entry?.policy == 'blocking'  || entry.policy === 'detecting') && domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                <Button loading={loading} type="primary" style={{marginRight: 10}} onClick={() => removeFromList(entry.domain, domains)} >{device?.deviceManagement == 'mdm' ? 'Remove from profile allow list' : 'Remove from allow list'}</Button>
                            }
                            {
                                (entry?.policy == 'passing'  || entry.policy === 'detecting') && blockDomains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                <Button loading={loading} type="primary" style={{background: '#a14838'}} onClick={() => removeFromList(entry.domain, blockDomains)}>{device?.deviceManagement == 'mdm' ?  'Remove from profile block list' : 'Remove from block list'}</Button>  
                            }
                        </div>
                    </>
                });
            })} />
    </>);
}
