import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Tag } from 'antd';
import {
  DeleteOutlined
} from '@ant-design/icons'


const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const EditableTable = ({
  headers,
  dataList,
  setDataList,
  ignoreCells,
  arrayCells
}) => {
  const [dataSource, setDataSource] = useState(() => {
    const data = dataList?.map((item, index) => {
      var row = {};
      item?.forEach((item, index) => {
        row[headers?.[index]?.trim()?.toLowerCase()] = item?.trim()
      });
      console.log(row)
      return {...row, key: index}
    })
    setDataList(data)
    return data
  });
  const [count, setCount] = useState(2);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setDataList(newData);
  };
  const defaultColumns = [
    ...headers?.filter(item =>  !ignoreCells?.includes(item?.trim()?.toLowerCase()))?.map(item => ({
      title: item?.trim(),
      dataIndex: item?.trim()?.toLowerCase(),
      render: (value) => (
        arrayCells?.includes(item?.trim()?.toLowerCase()) ? 
          <>
            {value?.trim()?.split(' ')?.map((tag) => {
              return (
                <Tag key={tag}>
                  {tag}
                </Tag>
              );
            })}
          </>
        :
          <>
            {value}
          </>
      ),
      editable: true,
    })),
    {
      title: '#',
      dataIndex: 'operation',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <DeleteOutlined />
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    const newData = {};
    headers?.forEach(item => {
      newData[item?.trim()?.toLowerCase()] = '---'
    });
    setDataSource([...dataSource, newData]);
    setDataList([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    setDataList(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add a row
      </Button>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};
export default EditableTable;