import { useEffect, useState } from "react"

import {
    Button,
    Input,
    Table,
    Alert,
    notification,
    Space,
    Popconfirm,
    Tooltip
} from 'antd'

import { CheckCircleTwoTone, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import {
    DiventryBlock
} from '../../Templates/Layout'


export function Emails({ onUpdate, values, kernel }) {

    const [dataSource, setDataSource] = useState([])
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false)

    useEffect(() => {
        if (!values.emails) return;

        const newDatasource = []
        for (let email of values.emails) {
            const Delete = () =>
                <Tooltip title="Delete email">
                    <Popconfirm
                        placement="left"
                        title={<>Delete {email.email}</>}
                        description="Are you sure you want to delete this email ? this operation is not reversible."
                        onConfirm={async () => {
                            var response = await kernel.api.post("/user/email/delete", { id: email.id })
                            if (response.error) {
                                notification.error({
                                    message: `Email error`,
                                    description: <>{response.error}</>,
                                    placement: "topRight",
                                })
                                // setOpen(false)
                                setConfirmLoading(false)
                                return
                            }

                            notification.success({
                                message: `Email`,
                                description: "The email was removed",
                                placement: "topRight",
                            })

                            onUpdate()
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            shape="circle"
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </Tooltip>


            newDatasource.push({
                key: email.id,
                email: email.email,
                status: <Space>
                    {email.error ?
                        <Alert type="error" size="small" message={email.error} />
                        : null}

                    {email.activated !== true ? <>
                        Pending request
                        {/* <LoadingOutlined backgroundColor="orange" spin /> */}
                        <Button size="small" onClick={async () => {
                            var response = await kernel.api.post("/user/email/resent", { id: email.id })
                            if (response.error) {
                                notification.error({
                                    message: `Email error`,
                                    description: <>{response.error}</>,
                                    placement: "topRight",
                                })
                                // setOpen(false)
                                setConfirmLoading(false)
                                return
                            }

                            notification.success({
                                message: `Email`,
                                description: "Activation email resent",
                                placement: "topRight",
                            })

                        }}>Resent</Button>
                        <Delete />
                    </> : null}

                    {email.activated === true ? <>
                        Verify
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        <Delete />

                    </> : null}

                </Space>,
            })
        }
        setDataSource(newDatasource)
    }, [values]);

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            // title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '50%',
            align: "right",

        },
    ]

    const showPopconfirm = () => {
        setOpen(true)
    }

    const handleOk = async () => {
        setConfirmLoading(true)
        var response = await kernel.api.post("/user/email/add", { email })
        if (response.error) {
            notification.error({
                message: `Error adding email`,
                description: <>{response.error}</>,
                placement: "topRight",
            })
            // setOpen(false)
            setConfirmLoading(false)
            return
        }

        notification.success({
            message: `Email`,
            description: "Your new email has been added",
            placement: "topRight",
        })

        onUpdate()
        setTimeout(() => {
            setOpen(false)
            setConfirmLoading(false)
        }, 1000)
    };

    const handleCancel = () => {
        setOpen(false)
    };

    return (
        <>
            <DiventryBlock bottom={true}>
                <Popconfirm
                    open={open}
                    okButtonProps={{ loading: confirmLoading }}
                    placement="right"
                    title="Link a new email"
                    description={<>
                        <Input onChange={({ target }) => setEmail(target.value)} />
                    </>}
                    icon={<PlusOutlined color="black" />}
                    onConfirm={handleOk}
                    onCancel={handleCancel}
                    okText="Add"
                    cancelText="Cancel"
                >
                    <Button onClick={showPopconfirm} type="primary" icon={<PlusOutlined />}>Add an email</Button>
                </Popconfirm>
            </DiventryBlock>
            <DiventryBlock>
                <Table dataSource={dataSource} columns={columns} pagination={false} />
            </DiventryBlock>
        </>
    )
}
