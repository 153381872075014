import { useRef } from "react"
import { Col, Row } from 'antd';

import {
    Button,
    Form,
} from 'antd';

import {
    DiventryBlock
} from './Layout'

export function PlanTable({ data }) {
    const formRef = useRef() 

    return (
      <>
      <table className="price-table">
        <tbody>
          <tr>
            <td colSpan={5} className='price-table-popular'>{data.title}</td>
          </tr>
          <tr className="price-table-head">
            <td></td>
            {data.packages.map(pkg => (
              <td key={pkg.id}>{pkg.name}</td>
            ))}
          </tr>
          <tr>
            <td></td>
            {data.packages.map(pkg => (
              <td key={pkg.id} className="price">{pkg.price}</td>
            ))}
          </tr>
          {data.packages[0].features.map((feature, index) => (
            <tr key={index}>
              <td className={feature.value==="" ? "subtitle" : ""}>{feature.name}</td>
              {data.packages.map(pkg => (
                <td key={pkg.id}>
                  {typeof pkg.features[index].value === 'boolean' ? (
                    pkg.features[index].value ? <i className="fa fas fa-check"></i> : <i className="fa fas fa-times"></i>
                  ) : (
                    pkg.features[index].value
                  )}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td></td>
            {data.packages.map((cell, index) => (
              <td key={index} className="price">
                <Form
            ref={formRef}
            name={cell.id}
            onFinish={()=>console.log('cell',cell.id)}
        >
            <Button type="primary" htmlType="submit">Get started</Button>
        </Form >
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      {/* {data?.options && <PlanTable data={data?.options[0]} />} */}
    
       {/*  {data?.options && <Row> {data.options.map((option)=><PlanTable data={option} />)}</Row>} */}
      </>
    )
}

