import React, { useState, useEffect } from 'react'
import { DiventryBlock } from './Layout';
import { Empty, Card } from 'antd';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from "highcharts/highcharts-3d";
HC_exporting(Highcharts);
highcharts3d(Highcharts);


function Statistic({ kernel, endpoint, type, statName, statCategoryName, statTypes, title, globalStats, exclude, disabled }) {
    const [loaded, setLoaded] = useState(true)
    const [data, setData] = useState([])
    
    // async load
    useEffect(() => {
        async function fetchStats() {
            const raw = await kernel.api.get(`${endpoint}`)
            if (raw.error || !(Array.isArray(raw?.data?.values) || Array.isArray(raw?.data))) {
                setData([]);
                return
            }

            const newData = [];

            if (type === 'pie') {
                const newPieData = [{
                    name: statName,
                    data: raw?.data?.map((item) => [item?.[statCategoryName]?.tag, item?.values?.reduce((acc, val) => acc + val[1], 0)])
                    // data: Object.keys(raw?.data?.[statCategoryName]).map((stat, index) => ([raw?.data?.[statCategoryName]?.[stat], raw.data.values?.reduce((acc, item) => acc + item[index + 1], 0)]))
                }]
                setData(newPieData);
            } else {
                Object.keys(statTypes).forEach((stat, index) => {
                    newData.push({
                        name: stat,
                        data: raw.data.values?.map(item => ([new Date(item[0])?.toLocaleString(), item[index + 1]])),
                        step: true,
                        color: statTypes?.[stat],
                        visible: !disabled?.includes(stat) ?? true
                    })
                });
                setData(newData)
            }


        }

        fetchStats()
    }, [loaded, endpoint])


    var options;
    switch (type) {
        case 'line':
            options = {
                xAxis: {
                    categories: data?.[0]?.data?.map(item => item?.[0]),
                    allowDecimals: false,
                    labels: {
                        step: 20
                    }
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                title: {
                    text: null
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                series: data
            };
            break;

        case 'area':
            options = {
                chart: {
                    type: 'area'
                },
                title: null,
                xAxis: {
                    allowDecimals: false,
                    labels: {
                        step: 150
                    },
                    categories: data?.[0]?.data
                },
                yAxis: {
                    title: {
                        text: 'Request'
                    }
                },
                tooltip: {

                },
                plotOptions: {
                    area: {
                        fillOpacity: 0
                    }
                },
                credits: {
                    enabled: false
                },
                series: data?.filter((stat) => !exclude?.includes(stat?.name)/*  && stat?.name != 'bypassing' */)
            };
            break;

        case 'pie':

            options = {
                chart: {
                    type: 'pie',
                    options3d: {
                        enabled: true,
                        alpha: 45
                    }
                },
                title: {
                    text: '',
                    align: 'left'
                },
                subtitle: {
                    text: '',
                    align: 'left'
                },
                plotOptions: {
                    pie: {
                        // innerSize: 100,
                        depth: 45
                    }
                },
                series: data,
            };

            break;

        default:
            break;
    }


    return <>
        <DiventryBlock>
            {title && <h1 style={{ textAlign: 'center', margin: '22px 0' }} >{title}</h1>}
            {
                globalStats?.length ?
                    <Card gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={styles.statContainer}>
                        {
                            globalStats.map(stat => (
                                <Card.Grid style={{ width: `${(100 / globalStats?.length) - 2}%`, margin: '1%' }}>
                                    <h1 style={{ ...styles.statName, color: stat.color }}>{stat.name} {stat.unit}</h1>
                                    <span style={styles.statValue}>{stat.value.toLocaleString()}</span>
                                </Card.Grid>
                            ))
                        }
                    </Card>
                    : ''
            }
            {data.length === 0 ? <Empty /> :
                <HighchartsReact highcharts={Highcharts} options={options} />
            }
        </DiventryBlock>
    </>
}

export default Statistic


const styles = {
    filters: {
        margin: '30px 0'
    },
    statContainer: {
        marginTop: 25,
        marginBottom: 25,
        textAlign: 'center'
    },
    statName: {
        marginBottom: '-0.2em',
        fontWeight: 800
    },
    statValue: {
        color: '#777777',
        fontSize: '18px'
    }
}