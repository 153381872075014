// const os = require('os')
// const fs = require('fs')
// const https = require('https')
import axios from 'axios'
// import pack from "../../../package.json"


export default async function init(kernel) {
    kernel.log("Loading API")

    kernel.api = {}

    // const httpsAgent = new https.Agent({
    //     // rejectUnauthorized: false,
    // })

    function manageLoginRedirect(ret) {
        if (ret.data.login === true) {
            const checker = process.env.REACT_APP_ENV !== "development" ? window.location.pathname : window.location.hash.substring(1)
            if (/^\/session/.test(checker))
                return

            // build rebound
            var reboundURL = window.location.origin
            if (process.env.REACT_APP_ENV === "development")
                reboundURL += "/#"
            reboundURL += "/session/login"
            window.location.href = reboundURL
        }
    }

    kernel.api.get = async (resource) => {
        const url = `${kernel.config.central}${resource}`

        kernel.debug(`GET ${resource} > ${url}`, ['api'])
        const options = {
            url: url,
            // httpsAgent: this.httpsAgent,
            method: "GET",
            headers: {
                // "User-Agent": `DOHzel/${pack.version}`,
            }
        }
        if (kernel.session.current)
            options.headers.Authorization = kernel.session.current

        try {
            const ret = await axios(options)
            if (ret.data.error) {
                kernel.debug(`Network error: ${ret.data.error}`, ['api', 'get'])
                manageLoginRedirect(ret)
            }

            return (ret.data)
        } catch (e) {
            return ({ error: e.message, data: options.data })
        }
    }

    kernel.api.stream = async (resource) => {
        const url = `${kernel.config.central}${resource}`

        kernel.debug(`STREAM ${resource} > ${url}`, ['api', 'stream'])
        const options = {
            url: url,
            // httpsAgent: this.httpsAgent,
            // responseType: 'stream',
            responseType: 'blob',
            method: "GET",
            headers: {
                // "User-Agent": `DOHzel/${pack.version}`,
            }
        }
        if (kernel.session.current)
            options.headers.Authorization = kernel.session.current

        try {
            const ret = await axios(options)
            if (!ret.data) {
                kernel.debug(`Network error: ${ret.data.error}`, ['api'])
                manageLoginRedirect(ret)
            }

            return (ret.data)
        } catch (e) {
            return ({ error: e.message, data: options.data })
        }
    }

    kernel.api.post = async (resource, data) => {
        const url = `${kernel.config.central}${resource}`

        kernel.debug(`POST ${resource} > ${url}`, ['api'])
        const options = {
            url: url,
            // httpsAgent: this.httpsAgent,
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                // "User-Agent": `DOHzel/${pack.version}`
            },
            data
        }
        if (kernel.session.current)
            options.headers.Authorization = kernel.session.current
        try {
            const ret = await axios(options)
            if (ret.data.error) {
                kernel.debug(`Network error: ${ret.data.error}`, ['api', 'post'])
                manageLoginRedirect(ret)
            }
            return (ret.data)
        } catch (e) {
            return ({ error: e.message, data: options.data })
        }
    }

    kernel.api.head = async (resource) => {
        const url = `${kernel.config.central}${resource}`

        kernel.debug(`HEAD ${resource} > ${url}`, ['api'])
        const options = {
            url: url,
            // httpsAgent: this.httpsAgent,
            method: "HEAD",
            headers: {
                // "User-Agent": `DOHzel/${pack.version}`,
            }
        }
        if (kernel.session.current)
            options.headers.Authorization = kernel.session.current

        try {
            const ret = await axios(options)
            if (ret.data.error) {
                kernel.debug(`Network error: ${ret.data.error}`, ['api', 'head'])
                manageLoginRedirect(ret)
            }

            return (ret)
        } catch (e) {
            return ({ error: e.message, data: options.data })
        }
    }

    kernel.api.checkVersion = async () => {
        kernel.log("Checking API versions")
        const ret = await kernel.api.get('/version')
        if (!ret.error) {
            const currentApiVersion = localStorage.getItem("apiVersion")
            if (currentApiVersion !== ret.data.software) {
                localStorage.setItem("apiVersion", ret.data.software)
                window.location.reload()
            }
            kernel.api.version = currentApiVersion
        }
        return (ret.data)
    }
    await kernel.api.checkVersion()


    kernel.api.getStats = async (type) => {
        async function fetch() {
            const ret = await kernel.api.get(`/dohzel/device/stats/${type}`)
            if (!ret || ret.error) return (null)

            localStorage.setItem(`stats.${type}`, JSON.stringify(ret))
            return (ret)
        }

        try {
            const savedToken = JSON.parse(localStorage.getItem(`stats.${type}`))
            fetch()
            if (savedToken) return (savedToken)
        } catch (e) {
            return (fetch())
        }
    }

    kernel.api.link = (resource) => {
        return (`${kernel.config.central}${resource}`)
    }
}

