
import { useEffect, useState } from 'react'
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom"

import {
    Button,
    Descriptions,
    Form,
    Input,
    InputNumber,
    Modal,
    notification,
    Popconfirm,
    Select,
    Space,
    Spin,
    Table,
    Menu,
    Tabs,
    Dropdown
} from 'antd'

import {
    CaretLeftOutlined,
    DeleteOutlined,
    LeftOutlined,
    RightOutlined,
    SettingOutlined,
    DownOutlined
} from '@ant-design/icons'

import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'

import {
    DiventryBlock,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryLayoutHeader,
    DiventryLayoutThreatDB
} from '../../../Templates/Layout'
import { DiventryMenu } from '../../../Templates/Menu'

import InsertIndicators from '../../../Templates/InsertIndicators'
import {
    ThreatDBTag
} from '../../../Templates/Tag'
import Statistic from './../../../Templates/Statistic'

HC_exporting(Highcharts);

const columns = [
    {
        title: 'Network',
        dataIndex: 'link',
        key: 'link',
    },
    {
        title: 'First',
        dataIndex: 'first',
        key: 'first',
        render: (text, record, index) => {
            return (new Date(text).toLocaleString())
        }
    },
    {
        title: 'Update',
        dataIndex: 'update',
        key: 'update',
        render: (text, record, index) => {
            return (new Date(text).toLocaleString())
        }
    },
    {
        title: 'Retention',
        dataIndex: 'retention',
        key: 'retention',
        render: (text, record, index) => {
            var usedRetention = record.retention
            var rewrite = false
            if (record.userRetention > -1) {
                rewrite = true
                usedRetention = record.userRetention
            }
            if (usedRetention == 0)
                return ("Permanent" + (rewrite === true ? " (rewrite)" : ""))
            return (
                new Date(record.update + (usedRetention * 1000)).toLocaleString() +
                (rewrite === true ? " (rewritten)" : "")
            )
        }
    },
    {
        title: 'Tags',
        dataIndex: 'tagsShow',
        key: 'tagsShow',
    },
    {
        title: '',
        dataIndex: 'buttons',
        key: 'buttons',
        align: "right"
    },
]

const downloadFormats = {
    raw: "RAW JSON format",
    wTags: "Per line network with Tags",
    woTags: "Per line Network without Tags"
}

const ipRanges = {
    ipRange: "w/ Network ranges & IPs",
    rangeOnly: "Only network ranges",
    ipOnly: "Only IPs"
}

const statTypes = {
    Added: '#3498db',
    Updated: '#e67e22',
    Removed: '#c0392b'
}

export function ListHeaderIP({ kernel, id, type }) {
    const [loaded, setLoaded] = useState(true)
    const [document, setDocument] = useState({})
    const [info, setInfo] = useState({})

    const [stats, setStats] = useState({
        status: "Not Yet",
        stats: {
            lines: "-",
            eta: null
        }
    })
    const [download, setDownload] = useState({
        format: "raw",
        ipFormat: "ipRange"
    })

    const navigate = useNavigate()

    useEffect(() => {
        var tm = "init"
        async function fetch() {

            // fetch information
            if (id !== "global") {
                const rinfo = await kernel.api.get(`/threatdb/list/get/${id}`)
                if (rinfo.error || !rinfo.data) {
                    notification.error({
                        message: 'Error getting API',
                        description: rinfo ? rinfo.error : null
                    })
                    return (navigate("/threatdb/list"))
                }

                setInfo(rinfo.data)
            }

            const raw = await kernel.api.get(`/threatdb/list/build/${id}`)
            if (!raw.error && raw.data) {
                setDocument(raw.data)
                if (raw.data && raw.data[type])
                    setStats(raw.data[type])
            }

            if (tm)
                tm = setTimeout(fetch, 5000)
        }
        fetch()

        return (() => {
            if (tm) {
                if (tm !== "init")
                    clearTimeout(tm)
                tm = null
            }
        })
    }, [loaded])


    return (
        <Descriptions bordered size="small">
            <Descriptions.Item label={type + " Entries"}>{stats.stats.lines}</Descriptions.Item>
            <Descriptions.Item label="Rebuild Status">{stats.status}</Descriptions.Item>
            <Descriptions.Item label="Last ETA">{stats.stats.eta ? stats.stats.eta + "ms" : "n/a"}</Descriptions.Item>

            {stats.request ?
                <>

                    <Descriptions.Item label="Last rebuild">{new Date(stats.updated).toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label="Full Download">
                        {/* <p> */}
                        <Space.Compact >
                            <Select
                                style={{ width: 250 }}
                                placeholder="Format"
                                value={download.format}
                                onChange={(value) => {
                                    setDownload({
                                        ...download,
                                        format: value
                                    })
                                }}
                                options={
                                    Object.keys(downloadFormats).map((key) => {
                                        return ({ value: key, label: downloadFormats[key] })
                                    })
                                }
                            />
                            <Select
                                style={{ width: 200 }}
                                placeholder="IP Data"
                                value={download.ipFormat}
                                onChange={(value) => {
                                    setDownload({
                                        ...download,
                                        ipFormat: value
                                    })
                                }}
                                options={
                                    Object.keys(ipRanges).map((key) => {
                                        return ({ value: key, label: ipRanges[key] })
                                    })
                                }
                            />
                            <Button
                                type="primary"
                                onClick={() => {
                                    const query = []

                                    for (var key in download) {
                                        const value = download[key]
                                        if (value === false)
                                            continue
                                        else if (Array.isArray(value))
                                            query.push(`${key}=${value.join(",")}`)
                                        else
                                            query.push(`${key}=${value}`)
                                    }

                                    window.open(
                                        kernel.config.central +
                                        `/threatdb/list/download/${id}/${type}?${query.join("&")}`,
                                        '_blank'
                                    )
                                }}
                            >Download</Button>
                        </Space.Compact>
                        {/* </p> */}

                        {/* <p>

                            <Space size="small">
                                <Switch
                                    checkedChildren="w/ Blocking Decision"
                                    unCheckedChildren="wo/ Blocking Decision (raw)"
                                    value={download.wDecision}
                                    onChange={() => {
                                        setDownload({
                                            ...download,
                                            wDecision: !download.wDecision,
                                            allowlistSecurity: true,
                                            allowlistAds: true,
                                            allowlistTracking: true,
                                        })
                                    }}
                                />
                                {download.wDecision === true ? <>
                                    <Switch
                                        checkedChildren="w/ Apply Security"
                                        unCheckedChildren="wo/ Apply Security"
                                        value={download.allowlistSecurity}
                                        onChange={() => {
                                            setDownload({
                                                ...download,
                                                allowlistSecurity: !download.allowlistSecurity,
                                            })
                                        }}
                                    />
                                    <Switch
                                        checkedChildren="w/ Apply Ads"
                                        unCheckedChildren="wo/ Apply Ads"
                                        value={download.allowlistAds}
                                        onChange={() => {
                                            setDownload({
                                                ...download,
                                                allowlistAds: !download.allowlistAds,
                                            })
                                        }}
                                    />
                                    <Switch
                                        checkedChildren="w/ Apply Tracking"
                                        unCheckedChildren="wo/ Apply Tracking"
                                        value={download.allowlistTracking}
                                        onChange={() => {
                                            setDownload({
                                                ...download,
                                                allowlistTracking: !download.allowlistTracking,
                                            })
                                        }}
                                    />
                                </>
                                    : null}
                            </Space>
                        </p> */}

                    </Descriptions.Item>

                </> : null}
        </Descriptions>
    )
}


export function IPvXUpdateModal({ kernel, list, mode, id, query, isOpen, onChange }) {
    // for update
    const [insertionMode, setInsertionMode] = useState(false)
    const [isModalUpdate, setIsModalUpdate] = useState(false)
    const [loading, setSetLoading] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        async function fetch() {
            setSetLoading(true)
            const raw = await kernel.api.get(`/threatdb/list/view/${mode}/list/${list}?equal=${id}`)
            if (raw.error || !raw.data || raw.data.data.length === 0) {

                if (!query) {
                    notification.error({
                        message: 'Error getting API',
                        description: raw.error
                    })
                    setSetLoading(false)
                    onChange()
                    return
                }

                // manage insertion
                setSetLoading(false)
                setInsertionMode(true)
                form.setFieldsValue({
                    ip: query
                })
                return
            }

            const data = raw.data.data[0]
            data.tags = Object.keys(data.tags)
            data.tagsShow = data.tags.map((tag) => {
                return (<ThreatDBTag key={`${data.key}-${tag}`} kernel={kernel} tag={tag} />)
            })

            form.resetFields()
            form.setFieldsValue(data)
            setInsertionMode(false)
            setSetLoading(false)
        }

        if (isOpen === true)
            fetch()

        setIsModalUpdate(isOpen)
    }, [isOpen, id])

    // Update handler
    const handleUpdateOk = async () => {
        setSetLoading(true)

        if (insertionMode === true) {
            const data = [{ ...form.getFieldsValue(), type: 0 }]
            const ret = await kernel.api.post(`/threatdb/list/insert/${list}`, data)
            if (!ret || ret.error) {
                notification.error({
                    message: 'Error adding source',
                    description: ret.error
                })
            }
            else {
                notification.success({
                    message: 'Indicator(s) have been pushed into the queue'
                })
            }
        }
        else {
            const data = {
                ...form.getFieldsValue()
            }

            const ret = await kernel.api.post(`/threatdb/list/manipulation/${mode}/update/${list}`, data)
            if (ret.error) {
                notification.error({
                    message: 'Error updating information',
                    description: Object.values(ret.fields).map((el) => <div key={el}>{el}</div>)
                })
                setSetLoading(false)
                return
            }
            if (ret.data === true) {
                notification.success({
                    message: 'Entry updated'
                })
            }
            else {
                notification.warning({
                    message: 'Update request is queued'
                })
            }
        }

        setSetLoading(false)
        setIsModalUpdate(false)
        onChange()
    }

    const handleUpdateCancel = () => {
        setIsModalUpdate(false)
        onChange()
    }

    return (
        <>
            {insertionMode !== true ?
                <Modal title="Update entry information" open={isModalUpdate} onOk={handleUpdateOk} onCancel={handleUpdateCancel}>
                    <Spin spinning={loading}>
                        <Form
                            // layout="vertical"
                            form={form}
                        >
                            <Form.Item label="Internal key name" name="key">
                                <Input disabled={true} />
                            </Form.Item>

                            <Form.Item label="Network range" name="network">
                                <Input disabled={true} />
                            </Form.Item>

                            <Form.Item label="Current retention" name="retention">
                                <InputNumber disabled={true} />
                            </Form.Item>

                            <Form.Item label="Data retention" name="userRetention" help="0=permanent -1=let DB decide">
                                <InputNumber addonAfter="seconds" />
                            </Form.Item>

                            {list !== "global" ?
                                <Form.Item label="List tags" name="tags">
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                : null}
                            <Form.Item label="Tags forcing" name="userTags">
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>


                        </Form>
                    </Spin>
                </Modal>
                :
                <Modal title="Update entry information" open={isModalUpdate} onOk={handleUpdateOk} onCancel={handleUpdateCancel}>
                    <Spin spinning={loading}>
                        <Form
                            form={form}
                        >
                            <Form.Item label="IP Address or network CIDR" name="ip">
                                <Input />
                            </Form.Item>

                            <Form.Item label="Tags" name="tags">
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    placeholder="Type to add tags informations"
                                />
                            </Form.Item>

                        </Form>
                    </Spin>
                </Modal>
            }
        </>
    )
}

export function IPvXData({ kernel, mode, title }) {
    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)
    const [document, setDocument] = useState({ data: [] })
    const [info, setInfo] = useState(null)

    // for update
    const [isModalUpdate, setIsModalUpdate] = useState(false)
    const [updateData, setUpdateData] = useState({})

    // for navigation
    const navigate = useNavigate()
    const { id } = useParams()
    const [searchParams] = useSearchParams();
    const [selectedSize, setSelectedSize] = useState(localStorage?.getItem('sizePager') ?? '100');
    
    const handleSizeClick = (e) => {
        const selectedLabel = e.key; // Ou e.item.props.children pour obtenir le texte
        console.log('selectedLabel : ', selectedLabel)
        setSelectedSize(selectedLabel);
        localStorage?.setItem('sizePager', selectedLabel)
        navigate(`/threatdb/list/${mode}/${id}/data?limit=${selectedLabel}`);
    };

    const sizeChangerProps = (
        <Menu onClick={handleSizeClick}>
          <Menu.Item key="50">50 per page</Menu.Item>
          <Menu.Item key="100">100 per page</Menu.Item>
          <Menu.Item key="200">200 per page</Menu.Item>
          <Menu.Item key="300">300 per page</Menu.Item>
          <Menu.Item key="400">400 per page</Menu.Item>
          <Menu.Item key="500">500 per page</Menu.Item>
        </Menu>
    );

    useEffect(() => {
        async function fetch() {
            setLoading(true)
            // fetch information
            if (id !== "global") {
                const rinfo = await kernel.api.get(`/threatdb/list/get/${id}`)
                if (rinfo.error) {
                    notification.error({
                        message: 'Error getting API',
                        description: raw ? raw.error : null
                    })
                    setLoading(false)
                    return (navigate("/threatdb/list"))
                }

                setInfo(rinfo.data)
            }

            // prepare table
            const newTable = []
            const next = searchParams.get('next')
            const params = []
            if (next)
                params.push(`gte=${next}`)

            const raw = await kernel.api.get(`/threatdb/list/view/${mode}/list/${id}?${params.join("&")}&limit=${selectedSize}`)
            if (raw.error || !raw.data) {
                notification.error({
                    message: 'Error getting API',
                    description: raw ? raw.error : null
                })
                setLoading(false)
                return (navigate("/threatdb/list"))
            }

            var first = null
            for (let data of raw.data.data) {
                if (!first) first = data.key

                data.link = <Link to={`/threatdb/show/ip?index=${data.network}`}>{data.network}</Link>

                data.tags = Object.keys(data.tags)
                data.tagsShow = data.tags.map((tag) => {
                    return (<ThreatDBTag key={`${data.key}-${tag}`} kernel={kernel} tag={tag} />)
                })

                // process button
                data.buttons = <Space.Compact size="small">
                    <Popconfirm
                        placement="bottom"
                        title="Delete this entry ?"
                        description="The entry will be permanently deleted"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={async () => {
                            const ret = await kernel.api.post(
                                `/threatdb/list/manipulation/${mode}/remove/${id}`,
                                { key: data.key }
                            )
                            if (ret.error) {
                                notification.error({
                                    message: 'Error deleting key'
                                })
                                return
                            }
                            if (ret.data === true) {
                                notification.success({
                                    message: 'Entry was deleted'
                                })
                            }
                            else {
                                notification.warning({
                                    message: 'Delete request is queued'
                                })
                            }
                            setLoaded(!loaded)
                        }}
                    >
                        <Button ><DeleteOutlined /></Button>
                    </Popconfirm>

                    <Button onClick={() => {
                        setUpdateData(data.key)
                        setIsModalUpdate(true)
                    }}><SettingOutlined /></Button>
                </Space.Compact>
                newTable.push(data)
            }

            raw.data.data = newTable
            setDocument(raw.data)
            setLoading(false)
        }
        fetch()
    }, [loaded, searchParams])

    return (

        <>
            <IPvXUpdateModal
                kernel={kernel}
                isOpen={isModalUpdate}
                onChange={() => setIsModalUpdate(false)}
                list={id}
                mode={mode}
                id={updateData}
            />

            <p>
                <Space>
                    <Space.Compact>
                        <Button onClick={() => {
                            if (id === "global") 
                                navigate(`/threatdb/list`)
                            else
                                navigate(`/threatdb/list/detail/${id}`)
                        }}>
                            <CaretLeftOutlined />
                        </Button>
                    </Space.Compact>

                    <Space.Compact>
                        <Button disabled={loading} onClick={() => {
                            navigate(`/threatdb/list/${mode}/${id}/data`);
                            setLoaded(!loaded);
                        }}>
                            Go first
                        </Button>

                        <Button type="primary" disabled={!document.prevCursor ? true : false} onClick={() => {
                            const params = []
                            params.push(`next=${document.prevCursor}`)
                            navigate(`/threatdb/list/${mode}/${id}/data?${params.join("&")}`)
                            setLoaded(!loaded)
                        }}>
                            <LeftOutlined /> Prev
                        </Button>

                        <Button type="primary" disabled={!document.nextCursor ? true : false} onClick={() => {
                            const params = []
                            params.push(`next=${document.nextCursor}`)
                            navigate(`/threatdb/list/${mode}/${id}/data?${params.join("&")}`)
                            setLoaded(!loaded)
                        }}>
                            Next <RightOutlined />
                        </Button>
                    </Space.Compact>

                    <Dropdown overlay={sizeChangerProps}>
                        <Button>
                            <Space>
                            {selectedSize} per page
                            <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </Space>
            </p>
            <DiventryBlock bottom={true}>
                <ListHeaderIP kernel={kernel} id={id} type={mode} />
            </DiventryBlock>
            <DiventryBlock>
                <Table
                    size="small"
                    pagination={false}
                    dataSource={document.data}
                    columns={columns}
                    loading={loading}
                />
            </DiventryBlock>
        </>
    )
}



export function IPvXStatistics({ kernel, id, mode }) {
    const [loaded, setLoaded] = useState(true)
    const [data, setData] = useState([])

    // async load
    /* useEffect(() => {
        async function fetchStats() {
            const raw = await kernel.api.get(`/threatdb/list/stats/${id}/last90d?type=${mode}`)
            if (raw.error || !Array.isArray(raw?.data?.values))
                return
            const newData = []

            Object.keys(statTypes).forEach((stat, index) => {
                newData.push({
                    name: stat,
                    data: raw.data.values?.map(item => ([new Date(item[0])?.toLocaleString(), item[index + 1]])),
                    step: true,
                    color: statTypes?.[stat]
                })
            });

            setData(newData)
        }

        fetchStats()
    }, [loaded]) */

    /* const options = {
        xAxis: {
            categories: data?.[0]?.data?.map(item => item?.[0]),
            allowDecimals: false,
            labels: {
                step: 20
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                marker:{
                    enabled: false
                }
            }
        },
        title: {
            text:null
        },
        series: data
    } */

    return <>
        <Statistic
            kernel={kernel}
            endpoint={`/threatdb/list/stats/${id}/last90d?type=${mode}`}
            type='line'
            statTypes={{
                Added: '#3498db',
                Updated: '#e67e22',
                Removed: '#c0392b'
            }}
        />
        {/* <DiventryBlock>
            {data.length === 0 ? <Empty /> :
                <div style={{ height: 'auto' }}>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
            }
        </DiventryBlock> */}
    </>
}

export function IPvX({ kernel, mode, title }) {
    const [loaded, setLoaded] = useState(true)

    const [info, setInfo] = useState(null)
    const navigate = useNavigate()
    const { id, page1 } = useParams()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        async function fetch() {
            return
            // fetch information
            if (id !== "global") {
                const rinfo = await kernel.api.get(`/threatdb/list/get/${id}`)
                if (rinfo.error) {
                    notification.error({
                        message: 'Error getting API',
                        description: rinfo.error
                    })
                    return (navigate("/threatdb/list"))
                }

                setInfo(rinfo.data)
            }

        }
        fetch()
    }, [loaded, searchParams])

    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title={info ? info.name : "Global Master"}
                description={title}
            />
            <DiventryLayoutContent>
                <p>
                <Space>
                        <Button onClick={() => id === "global" ?
                            navigate(`/threatdb/list`) :
                            navigate(`/threatdb/list/detail/${id}`)}><CaretLeftOutlined /> Back
                        </Button>
                        {
                            id !== "global" &&
                            <InsertIndicators kernel={kernel} list={id} />
                        }
                    </Space>
                </p>

                <Tabs
                    type="card"
                    defaultActiveKey={page1}
                    onTabClick={(key) => {
                        if (id === "global")
                            navigate(`/threatdb/global/${mode}/${key}`)
                        else
                            navigate(`/threatdb/list/${mode}/${id}/${key}`)
                    }}
                    items={[
                        {
                            label: "Data",
                            key: "data",
                            children: <>
                                <DiventryBlock bottom={true}>
                                    <IPvXData kernel={kernel} mode={mode} id={id} info={info} />
                                </DiventryBlock>
                            </>
                        },
                        {
                            label: "Statistics",
                            key: "statistics",
                            children: <DiventryBlock>
                                <IPvXStatistics kernel={kernel} mode={mode} id={id} info={info} />
                            </DiventryBlock>
                        },
                        // {
                        //     label: "Tags",
                        //     key: "tags",
                        //     children: <DiventryBlock>

                        //     </DiventryBlock>
                        // },
                    ]}
                />

            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutThreatDB>
    )

}


export function IPv4(props) {
    return (<IPvX {...props} mode="ipv4" title="IPv4 into the ThreadDB" />)
}

export function IPv6(props) {
    return (<IPvX {...props} mode="ipv6" title="IPv6 into the ThreadDB" />)
}