
import { CaretLeftOutlined } from '@ant-design/icons'
import {
    Button,
    Descriptions,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Select,
    Space,
    Switch,
    Table,
    Tabs,
    Tag,
    notification
} from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exporting from 'highcharts/modules/exporting'

import InsertIndicators from '../../../Templates/InsertIndicators'
import {
    DiventryBlock,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryLayoutHeader,
    DiventryLayoutThreatDB,
    DiventryTitle
} from '../../../Templates/Layout'
import { DiventryMenu } from '../../../Templates/Menu'

import { Logs } from './Logs'

import { ListHeaderDomain } from './Domains'
import { ListHeaderIP } from './IP'
import { Permissions } from './Permissions'

HC_exporting(Highcharts);

const columns = [
    {
        title: 'URL',
        dataIndex: 'link',
        key: '_id'
    },
    {
        title: 'Fetching mode',
        dataIndex: 'type',
        // key: 'type',
    },
    {
        title: 'Tags',
        dataIndex: 'forcingTags',
        // key: 'forcingTags',
    },
    {
        title: '',
        dataIndex: 'buttons',
        // key: 'buttons',
        align: "right"
    },
]

const fetchingModes = {
    domain: "Single domain per line",
    hostDomain: "Host format domain per line",
    ipPort: "IP+port per line",
    ip: "Single IP per line",
    suricata: "Suricata rules based"
}

const statTypes = {
    Added: '#3498db',
    Updated: '#e67e22',
    Removed: '#c0392b'
}


function ListStatistic({ kernel, id }) {
    const [loaded, setLoaded] = useState(true)
    const [data, setData] = useState([])

    // async load
    useEffect(() => {
        async function fetchStats() {
            const raw = await kernel.api.get(`/threatdb/list/stats/${id}/last90d`)
            if (raw.error || !Array.isArray(raw?.data?.values))
                return
            const newData = []
            Object.keys(statTypes).forEach((stat, index) => {
                newData.push({
                    name: stat,
                    data: raw.data.values?.map(item => ([new Date(item[0])?.toLocaleString(), item[index + 1]])),
                    step: true,
                    color: statTypes?.[stat]
                })
            });

            setData(newData)
        }

        fetchStats()
    }, [loaded])

    const options = {
        xAxis: {
            categories: data?.[0]?.data?.map(item => item?.[0]),
            allowDecimals: false,
            labels: {
                step: 20
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                marker:{
                    enabled: false
                }
            }
        },
        title: {
            text:null
        },
        series: data
    }

    return <>
        {data.length > 0 ? <DiventryBlock bottom={true}>
            <div style={{ height: 'auto' }}>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </DiventryBlock> : null}
    </>
};

export function Detail({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [currentUpdateSourceUrl, setCurrentUpdateSourceUrl] = useState('')

    // main document
    const [info, setInfo] = useState({
        permissions: {}
    })

    // for source addition
    const [isModalSource, setIsModalSource] = useState(false)
    const [titleModalSource, setTitleModalSource] = useState("")
    const [sourceForm] = Form.useForm()

    // for update
    const [isModalUpdate, setIsModalUpdate] = useState(false)
    const [updateForm] = Form.useForm()

    // for source update
    const [isModalSourceUpdate, setIsModalSourceUpdate] = useState(false)

    // for navigation
    const navigate = useNavigate()
    const { id } = useParams()

    // async load
    useEffect(() => {
        async function fetchInformation() {
            const raw = await kernel.api.get(`/threatdb/list/get/${id}`)
            if (!raw || raw.error || !raw.data) return (navigate("/threatdb/list"))
            setInfo(raw.data)
        }
        fetchInformation()
    }, [loaded])

    // Source handler
    const handleSourceOk = async () => {
        const data = {
            ...info,
            sources: Array.isArray(info.sources) ? [...info.sources] : []
        }
        data.sources.push(sourceForm.getFieldsValue())
        const ret = await kernel.api.post(`/threatdb/list/update/${id}`, data)
        if (ret.error) {
            notification.error({
                message: 'Error adding source',
                description: Object.values(ret.fields).map((el) => <div key={el}>{el}</div>)
            })
            return
        }
        notification.success({
            message: 'Source added'
        })
        setLoaded(!loaded)
        setIsModalSource(false)
    }
    const handleSourceCancel = () => {
        setIsModalSource(false)
    }

    // Update handler
    const handleUpdateOk = async () => {
        const data = {
            ...info,
            ...updateForm.getFieldsValue()
        }
        const ret = await kernel.api.post(`/threatdb/list/update/${id}`, data)
        if (ret.error) {
            notification.error({
                message: 'Error updating information',
                description: ret.fields ? Object.values(ret.fields).map((el) => <div key={el}>{el}</div>) : ret.error
            })
            return
        }
        notification.success({
            message: 'Information updated'
        })
        setLoaded(!loaded)
        setIsModalUpdate(false)
    }
    const handleUpdateCancel = () => {
        setIsModalUpdate(false)
    }

    // Update sources handler
    const handleUpdateSourceOk = async () => {
        const data = {
            ...info,
            sources: Array.isArray(info.sources) ? [...info.sources] : []
        }
        data.sources = data.sources.map((el) => {
            if (el.url === currentUpdateSourceUrl) 
                return updateForm.getFieldsValue()
            return el
        })

        const ret = await kernel.api.post(`/threatdb/list/update/${id}`, data)
        if (ret.error) {
            notification.error({
                message: 'Error adding source',
                description: Object.values(ret.fields).map((el) => <div key={el}>{el}</div>)
            })
            return
        }
        notification.success({
            message: 'Source updated successfully'
        })
        setLoaded(!loaded)
        setIsModalSourceUpdate(false)
    }
    const handleUpdateSourceCancel = () => {
        setIsModalSourceUpdate(false)
    }

    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title={info.name}
                description={`Source of data #${info._id} that can be leveraged by the ThreatDB`}
            />
            <DiventryLayoutContent>


                <Modal title={titleModalSource} open={isModalSource} onOk={handleSourceOk} onCancel={handleSourceCancel}>
                    <Form
                        layout="vertical"
                        form={sourceForm}
                    >
                        <Form.Item label="Source URL" name="url">
                            <Input placeholder="Type URL" />
                        </Form.Item>

                        <Form.Item label="Fetching mode" name="type">
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Tags Mode"
                                // onChange={handleChange}
                                options={
                                    Object.keys(fetchingModes).map((key) => {
                                        return ({ value: key, label: fetchingModes[key] })
                                    })
                                }
                            />
                        </Form.Item>

                        <Form.Item label="Tags forcing" name="tags">
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Tags Mode"
                            />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal title="Update list information" open={isModalUpdate} onOk={handleUpdateOk} onCancel={handleUpdateCancel}>
                    <Form
                        // layout="vertical"
                        form={updateForm}
                    >
                        <Form.Item label="Index name" name="index">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Name" name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item label="List visibility" name="public">
                            <Switch checkedChildren="Public" unCheckedChildren="Private" />
                        </Form.Item>

                        <Form.Item label="Allowlist permitted" name="allowlistAllowed">
                            <Switch checkedChildren="Allowed" unCheckedChildren="Disallowed" />
                        </Form.Item>

                        <Form.Item label="Merge into master" name="mergeMaster">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" />
                        </Form.Item>

                        <Form.Item label="Data retention" name="retention">
                            <InputNumber addonAfter="seconds" />
                        </Form.Item>

                        <Form.Item label="Share token" name="shareToken">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Website URL" name="website">
                            <Input />
                        </Form.Item>

                    </Form>
                </Modal>

                <Modal title={titleModalSource} open={isModalSourceUpdate} onOk={handleUpdateSourceOk} onCancel={handleUpdateSourceCancel}>
                    <Form
                        // layout="vertical"
                        form={updateForm}
                    >
                        <Form.Item label="Source URL" name="url">
                            <Input placeholder="Type URL" />
                        </Form.Item>

                        <Form.Item label="Fetching mode" name="type">
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Tags Mode"
                                // onChange={handleChange}
                                options={
                                    Object.keys(fetchingModes).map((key) => {
                                        return ({ value: key, label: fetchingModes[key] })
                                    })
                                }
                            />
                        </Form.Item>

                        <Form.Item label="Tags forcing" name="tags">
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Tags Mode"
                            />
                        </Form.Item>


                    </Form>
                </Modal>


                <p>
                    <Space>
                        <Button onClick={() => navigate("/threatdb/list")}><CaretLeftOutlined /></Button>

                        <Space.Compact>
                            <Button type="primary" onClick={() => {
                                navigate(`/threatdb/list/domains/${id}/data`)
                            }}>Domains</Button>
                            <Button type="primary" onClick={() => {
                                navigate(`/threatdb/list/ipv4/${id}/data`)
                            }}>IPv4</Button>
                            <Button type="primary" onClick={() => {
                                navigate(`/threatdb/list/ipv6/${id}/data`)
                            }}>IPv6</Button>
                        </Space.Compact>

                        <InsertIndicators kernel={kernel} list={id} />
                    </Space>

                </p>

                <Tabs
                    type="card"
                    defaultActiveKey="list-info"
                    items={[
                        {
                            label: "Information",
                            key: "list-info",
                            children: <>
                                <DiventryBlock bottom={true}>
                                    {info?.permissions?.admin === true ?
                                        <Button type="primary" onClick={() => {
                                            updateForm.setFieldsValue(info)
                                            setIsModalUpdate(true)
                                        }}>
                                            Update information
                                        </Button>
                                        : null}

                                </DiventryBlock>
                                <DiventryBlock bottom={true}>
                                    <Descriptions bordered>
                                        <Descriptions.Item label="ID">{info._id}</Descriptions.Item>
                                        <Descriptions.Item label="Index name">{info.index}</Descriptions.Item>
                                        <Descriptions.Item label="List name">{info.name}</Descriptions.Item>
                                        <Descriptions.Item label="Created at">{new Date(info.createdAt).toLocaleString()}</Descriptions.Item>
                                        <Descriptions.Item label="Updated at">{new Date(info.updatedAt).toLocaleString()}</Descriptions.Item>
                                        <Descriptions.Item label="API Key">{info.apiKey}</Descriptions.Item>
                                        <Descriptions.Item label="Allowlist permitted">{info.allowlistAllowed === true ? "Yes" : "No"}</Descriptions.Item>
                                        <Descriptions.Item label="Data merged to master">
                                            {info.mergeMaster === true ? "Yes" : "No"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Data retention">{info.retention > 0 ? info.retention : "Permanent"}</Descriptions.Item>
                                        <Descriptions.Item label="Share token">{info.shareToken}</Descriptions.Item>
                                    </Descriptions>
                                </DiventryBlock>


                                <ListStatistic kernel={kernel} id={id} />


                                <DiventryBlock bottom={true}>
                                    <DiventryTitle>Domains</DiventryTitle>
                                    <ListHeaderDomain kernel={kernel} id={id} />
                                </DiventryBlock>

                                <DiventryBlock bottom={true}>
                                    <DiventryTitle>IPv4</DiventryTitle>
                                    <ListHeaderIP kernel={kernel} id={id} type="ipv4" />
                                </DiventryBlock>

                                <DiventryBlock>
                                    <DiventryTitle>IPv6</DiventryTitle>
                                    <ListHeaderIP kernel={kernel} id={id} type="ipv6" />
                                </DiventryBlock>
                            </>
                        },
                        {
                            label: "Sources management",
                            key: "list-sources",
                            children: <>
                                {
                                    info?.permissions?.admin &&
                                    <DiventryBlock bottom={true}>
                                        <Button onClick={() => {
                                            setTitleModalSource("Add list source")
                                            sourceForm.resetFields()
                                            setIsModalSource(true)
                                        }}>
                                            Add source
                                        </Button>
                                    </DiventryBlock>
                                }
                                <DiventryBlock>
                                    <Table
                                        dataSource={Array.isArray(info.sources) ? info.sources.map((item, index) => {
                                            const nItem = { ...item, key: item?.url }
                                            if (!Array.isArray(nItem.tags))
                                            nItem.tags = []
                                            nItem.link = <a href={nItem.url} target="_blank" rel="noreferrer">{nItem.url}</a>
                                            nItem.forcingTags = nItem.tags.map((tag) => <Tag key={nItem.url + "tag" + tag}>{tag}</Tag>)
                                            nItem.buttons = info?.permissions?.admin ?
                                            <Space.Compact>
                                                <Button size="small" key={nItem.url + "update"} onClick={() => {
                                                    setTitleModalSource(`Update list source`)
                                                    setCurrentUpdateSourceUrl(nItem.url);
                                                    updateForm.resetFields()
                                                    updateForm.setFieldsValue({...nItem})
                                                    setIsModalSourceUpdate(true)
                                                }}>
                                                    Update
                                                </Button>

                                                <Popconfirm
                                                    placement="left"
                                                    title="Delete this source ?"
                                                    description="Deleting this item will disable fetching on the source"
                                                    okText="Yes"
                                                    cancelText="No"
                                                    onConfirm={async () => {
                                                        const data = {
                                                            ...info,
                                                            sources: Array.isArray(info.sources) ? [...info.sources] : []
                                                        }
                                                        data.sources = data.sources.filter((el) => {
                                                            if (el.url === nItem.url) return (false)
                                                            return (true)
                                                        })

                                                        const ret = await kernel.api.post(`/threatdb/list/update/${id}`, data)
                                                        if (ret.error) {
                                                            notification.error({
                                                                message: 'Error adding source',
                                                                description: Object.values(ret.fields).map((el) => <div key={el}>{el}</div>)
                                                            })
                                                            return
                                                        }
                                                        notification.success({
                                                            message: 'Source deleted'
                                                        })
                                                        setLoaded(!loaded)
                                                    }}
                                                >
                                                    <Button size="small" key={nItem.url + "delete"}>Delete</Button>
                                                </Popconfirm>
                                            </Space.Compact>
                                            :
                                            []

                                            return (nItem)
                                        }) : []}
                                        columns={columns}
                                    />
                                </DiventryBlock>
                            </>
                        },
                        info?.permissions?.admin && {
                            label: "Permissions",
                            key: "list-permissions",
                            children: <DiventryBlock>
                                <Permissions kernel={kernel} list={id} />
                            </DiventryBlock>
                        },
                        {
                            label: "Logs",
                            key: "list-logs",
                            children: <DiventryBlock>
                                <Logs kernel={kernel} list={id} />
                            </DiventryBlock>
                        },
                        info?.permissions?.admin && {
                            label: "Advanced settings",
                            key: "list-advanced",
                            children: <DiventryBlock>
                                <Space.Compact>
                                    <Popconfirm
                                        placement="bottom"
                                        title="Delete this list ?"
                                        description="Data will be deleted and merged data will be removed from master"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={async () => {
                                            const ret = await kernel.api.get(`/threatdb/list/delete/${id}`)
                                            if (ret.error) {
                                                notification.error({
                                                    message: 'Error deleting source'
                                                })
                                                return
                                            }
                                            navigate("/threatdb/list")
                                        }}
                                    >
                                        <Button type="primary" danger>Delete the list</Button>
                                    </Popconfirm>

                                    <Popconfirm
                                        placement="bottom"
                                        title="Clean this list ?"
                                        description="All items from domains, ipv4 and ipv6 will be cleaned"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={async () => {
                                            const ret = await kernel.api.get(`/threatdb/list/clean/${id}`)
                                            if (ret.error) {
                                                notification.error({
                                                    message: 'Error clean source'
                                                })
                                                return
                                            }
                                            notification.success({
                                                message: 'Cleaning message has been sent'
                                            })
                                        }}
                                    >
                                        <Button type="default">Clean the list</Button>
                                    </Popconfirm>
                                </Space.Compact>
                            </DiventryBlock>
                        },
                    ]}
                />

            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutThreatDB>
    );
}