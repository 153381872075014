
import { useEffect, useState } from 'react'
import { useSearchParams, useParams, useNavigate } from "react-router-dom"
import { Content } from '../../Templates'

import { DomainUpdateModal } from './List/Domains'
import { IPvXUpdateModal } from './List/IP'

import Flag from 'react-world-flags'
import CountryList from "countries-list"

import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber,
    ColorPicker
} from 'antd'

import {
    SettingOutlined,
    DeleteOutlined,
    CaretLeftOutlined
} from '@ant-design/icons'


import { DiventryMenu } from '../../Templates/Menu'
import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventryTitle
} from '../../Templates/Layout'

import {
    ShowRetention,
    ShowTag
} from "./index"

import { ThreatDBSearch } from '../../Templates/ThreatDBSearch'



export function ShowDomain({ kernel, user, document }) {
    const [columns, setColumns] = useState([
        {
            title: 'List',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tags',
            dataIndex: 'forcingTags',
            // key: 'forcingTags',
            align: "right",
            render: (text, record, index) => {
                return (<>
                    {Object.keys(record.result.tags).map((tag) => {
                        return (<ShowTag kernel={kernel} key={`tag-${record.id}-${tag}`} tag={tag} />)
                    })}
                </>)
            }
        }
    ])

    const [loaded, setLoaded] = useState(true)

    const [data, setData] = useState([])

    // for update
    const [isModalUpdate, setIsModalUpdate] = useState(false)
    const [updateList, setUpdateList] = useState(null)
    const [updateId, setUpdateId] = useState(null)

    useEffect(() => {
        if (user.admin === true && columns.length !== 3) {
            const newColumns = [...columns]
            newColumns.push({
                title: '',
                dataIndex: 'buttons',
                key: 'buttons',
                align: "right"
            })
            setColumns(newColumns)
        }

        const newData = []

        for (let item of document.lists) {
            if (item.deleting)
                item.name = <><Tag>Deleting</Tag> {item.name}</>

            // process button
            item.buttons = user.admin === true ? <Space.Compact size="small">
                <Popconfirm
                    placement="bottom"
                    title="Delete this entry ?"
                    description="The entry will be permanently deleted"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={async () => {
                        const ret = await kernel.api.post(
                            `/threatdb/list/manipulation/domain/remove/${item.id}`,
                            { key: item.result.key }
                        )
                        if (ret.error) {
                            notification.error({
                                message: 'Error deleting key'
                            })
                            return
                        }
                        if (ret.data === true) {
                            notification.success({
                                message: 'Entry was deleted'
                            })
                            item.deleting = true
                            setData([...document.lists])
                            setLoaded(!loaded)
                        }
                        else {
                            notification.warning({
                                message: 'Delete request is queued'
                            })
                            item.deleting = true
                            setData([...document.lists])
                            setLoaded(!loaded)
                        }
                    }}
                >
                    <Button disabled={item.deleting}><DeleteOutlined /></Button>
                </Popconfirm>

                <Button disabled={item.deleting} onClick={() => {
                    setUpdateList(item.id)
                    setUpdateId(item.result.key)
                    setIsModalUpdate(true)
                }}><SettingOutlined /></Button>

            </Space.Compact> : null

            newData.push(item)
        }
        setData(newData)
    }, [document, loaded])

    return (
        <>
            <DomainUpdateModal
                kernel={kernel}
                isOpen={isModalUpdate}
                onChange={() => setIsModalUpdate(false)}
                list={updateList}
                id={updateId}
            />

            {document.master ?
                <DiventryBlock >
                    <DiventryTitle>Presence in the master list</DiventryTitle>
                    <Descriptions bordered>
                        <Descriptions.Item span={4} label="Domain">
                            <strong>{document.master.wildcard === true ? "*." : null}{document.master.domain}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="Wildcard">{document.master.wildcard === true ? "Yes" : "No"}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Data retention">
                            <ShowRetention kernel={kernel} last={document.master.update} retention={document.master.retention} />
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="First detection">{new Date(document.master.first).toLocaleString()}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Last detection">{new Date(document.master.update).toLocaleString()}</Descriptions.Item>

                        <Descriptions.Item span={4} label="Tags">
                            {Object.keys(document.master.tags).map((tag) => {
                                return (<ShowTag kernel={kernel} key={`tag-master-${tag}`} tag={tag} />)
                            })}
                        </Descriptions.Item>
                    </Descriptions>
                </DiventryBlock>
                :
                null
            }

            <DiventryBlock>
                <DiventryTitle>General informations</DiventryTitle>
                <Descriptions bordered>
                    <Descriptions.Item label="In master list">{document.master ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label="Number of list">{document.lists.length}</Descriptions.Item>
                </Descriptions>
            </DiventryBlock>

            <DiventryBlock >
                {/* <DiventryTitle>List result</DiventryTitle> */}
                <Table
                    // bordered
                    size="small"
                    dataSource={data}
                    columns={columns}
                />
            </DiventryBlock>
        </>
    )
}

export function ShowIP({ kernel, user, document }) {


    const [columns, setColumns] = useState([
        {
            title: 'List',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tags',
            dataIndex: 'forcingTags',
            // key: 'forcingTags',
            align: "right",
            render: (text, record, index) => {
                return (<>
                    {Object.keys(record.result.tags).map((tag) => {
                        return (<ShowTag kernel={kernel} key={`tag-${record.id}-${tag}`} tag={tag} />)
                    })}
                </>)
            }
        }
    ])

    const [loaded, setLoaded] = useState(true)

    const [data, setData] = useState([])

    // for update
    const [isModalUpdate, setIsModalUpdate] = useState(false)
    const [updateList, setUpdateList] = useState(null)
    const [updateMode, setUpdateMode] = useState(null)
    const [updateId, setUpdateId] = useState(null)

    useEffect(() => {
        if (user.admin === true && columns.length !== 3) {
            const newColumns = [...columns]
            newColumns.push({
                title: '',
                dataIndex: 'buttons',
                key: 'buttons',
                align: "right"
            })
            setColumns(newColumns)
        }

        const newData = []

        for (let item of document.lists) {
            if (item.deleting)
                item.name = <><Tag>Deleting</Tag> {item.name}</>

            // process button
            item.buttons = user.admin === true ? <Space.Compact size="small">
                <Popconfirm
                    placement="bottom"
                    title="Delete this entry ?"
                    description="The entry will be permanently deleted"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={async () => {
                        const ret = await kernel.api.post(
                            `/threatdb/list/manipulation/${document.type}/remove/${item.id}`,
                            { key: item.result.key }
                        )
                        if (ret.error) {
                            notification.error({
                                message: 'Error deleting key'
                            })
                            return
                        }
                        if (ret.data === true) {
                            notification.success({
                                message: 'Entry was deleted'
                            })
                            item.deleting = true
                            setData([...document.lists])
                            setLoaded(!loaded)
                        }
                        else {
                            notification.warning({
                                message: 'Delete request is queued'
                            })
                            item.deleting = true
                            setData([...document.lists])
                            setLoaded(!loaded)
                        }
                    }}
                >
                    <Button disabled={item.deleting}><DeleteOutlined /></Button>
                </Popconfirm>

                <Button disabled={item.deleting} onClick={() => {
                    setUpdateList(item.id)
                    setUpdateMode(document.type)
                    setUpdateId(item.result.key)
                    setIsModalUpdate(true)
                }}><SettingOutlined /></Button>

            </Space.Compact> : null

            newData.push(item)
        }
        setData(newData)
    }, [document, loaded])

    return (
        <>
            <IPvXUpdateModal
                kernel={kernel}
                isOpen={isModalUpdate}
                onChange={() => setIsModalUpdate(false)}
                list={updateList}
                mode={updateMode}
                id={updateId}
            />

            {document.master ?
                <DiventryBlock >
                    <DiventryTitle>Presence in the master list</DiventryTitle>
                    <Descriptions bordered>
                        <Descriptions.Item span={4} label="IP Network">
                            <strong>{document.title}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item span={4} label="Data retention">
                            <ShowRetention kernel={kernel} last={document.master.update} retention={document.master.retention} />
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="First detection">{new Date(document.master.first).toLocaleString()}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Last detection">{new Date(document.master.update).toLocaleString()}</Descriptions.Item>

                        <Descriptions.Item span={4} label="Tags">
                            {Object.keys(document.master.tags).map((tag) => {
                                return (<ShowTag kernel={kernel} key={`tag-master-${tag}`} tag={tag} />)
                            })}
                        </Descriptions.Item>
                    </Descriptions>
                </DiventryBlock>
                :
                null
            }

            <DiventryBlock>
                <DiventryTitle>General informations</DiventryTitle>
                <Descriptions column={2} bordered>
                    {document.nro && document.nro.result.length > 0 ? <>
                        <Descriptions.Item label="CIDR">{document.nro.result[0].cidr}</Descriptions.Item>
                        <Descriptions.Item label="NRO Source">{document.nro.result[0].source.toUpperCase()}</Descriptions.Item>

                        <Descriptions.Item label="Country">{
                            document.nro.result[0]?.country?.length > 0 ?
                                <>
                                    <Space>
                                        {CountryList.countries[document.nro.result[0].country.toUpperCase()].name}
                                        <div style={{ paddingBottom: 3 }}>
                                            <Flag height="16" code={document.nro.result[0].country} />
                                        </div>
                                    </Space>
                                </>
                                : "N/A"
                        }</Descriptions.Item>
                        <Descriptions.Item label="Network name">{document.nro.result[0].networkName}</Descriptions.Item>

                        <Descriptions.Item label="Status">{document.nro.result[0].status}</Descriptions.Item>
                        {Array.isArray(document.nro.result[0].informations) ? <>
                            <Descriptions.Item label="Informations">{document.nro.result[0].informations.map((text) => {
                                return (<>{text}<br /></>)
                            })}</Descriptions.Item>
                        </> : null}
                    </> : null}
                    <Descriptions.Item label="In master list">{document.master ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label="Number of list">{document.lists.length}</Descriptions.Item>
                </Descriptions>
            </DiventryBlock>


            <DiventryBlock >
                <Table
                    size="small"
                    dataSource={data}
                    columns={columns}
                />
            </DiventryBlock>
        </>
    )
}

export function Show({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [document, setDocument] = useState(null)
    const [title, setTitle] = useState("Lookup in ThreatDB")
    const [index, setIndex] = useState("")
    const [user, setUser] = useState({})

    // for update
    const [isModalUpdate, setIsModalUpdate] = useState(false)
    const [updateList, setUpdateList] = useState(null)
    const [updateMode, setUpdateMode] = useState(null)
    const [updateId, setUpdateId] = useState(null)
    const [updateQuery, setUpdateQuery] = useState(null)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { type } = useParams()

    useEffect(() => {
        const index = searchParams.get('index')
        setIndex(index)
        async function fetch() {
            var cUser = await kernel.api.get("/user/me")
            if (cUser.error)
                return
            setUser(cUser.data)

            const raw = await kernel.api.get(`/threatdb/info/${type}?query=${index}`)
            if (!raw || raw.error) {
                notification.error({
                    message: 'Can not run search',
                    description: raw.error
                })
                return
            }
            setDocument(raw.data)
            setTitle(raw.data.title)
        }
        fetch()
    }, [loaded, searchParams])


    return (
        <>
            {updateMode === "domain" ?
                <DomainUpdateModal
                    kernel={kernel}
                    isOpen={isModalUpdate}
                    onChange={() => setIsModalUpdate(false)}
                    list={updateList}
                    query={updateQuery}
                    id={updateId}
                />
                :
                <IPvXUpdateModal
                    kernel={kernel}
                    isOpen={isModalUpdate}
                    onChange={() => setIsModalUpdate(false)}
                    list={updateList}
                    mode={updateMode}
                    query={updateQuery}
                    id={updateId}
                />
            }

            <DiventryLayoutThreatDB>
                <DiventryMenu kernel={kernel} />
                <DiventryLayoutHeader
                    description="Lookup ThreatDB database"
                    title={title}
                />
                <DiventryLayoutContent>

                    <p>
                        <Space>
                            <Space.Compact>
                                <Button onClick={() => {
                                    window.history.back()
                                }}><CaretLeftOutlined /></Button>
                            </Space.Compact>

                            <ThreatDBSearch kernel={kernel} value={index} />

                            {user.admin == true && kernel.config.counterMeasureList ?
                                <Button type="primary" onClick={() => {
                                    setUpdateList(kernel.config.counterMeasureList)
                                    setUpdateMode(document.type)
                                    setUpdateId(document.key)
                                    setUpdateQuery(document.query)
                                    setIsModalUpdate(true)
                                }}>Counter measure</Button>
                                : null}
                        </Space>
                    </p>

                    {!document || document.lists.length === 0 ?
                        <DiventryBlock>
                            <center>
                                No result found
                            </center>
                        </DiventryBlock>
                        :
                        <>
                            {type === "domain" ? <ShowDomain kernel={kernel} user={user} document={document} /> : null}
                            {type === "ip" ? <ShowIP kernel={kernel} user={user} document={document} /> : null}
                        </>
                    }


                </DiventryLayoutContent>

                <DiventryLayoutFooter kernel={kernel} />
            </DiventryLayoutThreatDB>
        </>
    );
}