import { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
    Button,
    Space,
    notification
} from 'antd';

import { DiventryLayoutSimple } from '../../Templates/LayoutSimple'

export function Validate({ kernel }) {
    let params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const onValidate = async (values) => {
        setLoading(true)
        const response = await kernel.api.post('/user/validate', { token: params.token })
        if (response.error) {
            notification.error({
                description: <>{response.error}</>
            })
            setLoading(false)
            return
        }
        notification.success({
            message: "You will be redirected"
        })
        setTimeout(() => navigate("/"), 1000)
    }

    return (
        <DiventryLayoutSimple>
            <Space>
                <Button size="large" type="primary" onClick={onValidate} loading={loading}>
                    Validate my account
                </Button>
            </Space>
            <p style={{ fontSize: "0.9em", paddingTop: "25px" }}>
                Please click on the button to validate and login with your account
            </p>
            {process.env.REACT_APP_ENV === "development" ?
                <p style={{ fontSize: "0.8em" }}>
                    {params.token}
                </p>
                : null}
        </DiventryLayoutSimple>
    )
}
