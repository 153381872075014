import { useEffect, useState } from "react"
import { Link } from "react-router-dom";

import {
    Button,
    Input,
    Table,
    Space,
    notification,
    Modal,
    Tag,
    Popconfirm,
    Tooltip,
    Form
} from 'antd'

import { CheckCircleTwoTone, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import {
    DiventryBlock
} from '../../Templates/Layout'

const columns = [
    {
        title: 'Reference',
        dataIndex: 'number',
        key: 'number',
        render: (text, record, index) => {
            return (<Link to={record.viewURL} target="_blank">{text}</Link>)
        }
    },
    {
        title: 'Date',
        dataIndex: 'effectiveAt',
        key: 'effectiveAt',
        sorter: (a, b) => a.effectiveAt - b.effectiveAt,
        render: (text, record, index) => {
            return (<div style={{}}>{new Date(text).toLocaleString()}</div>)
        }
    },
    {
        title: 'Amount',
        dataIndex: 'amountDue',
        key: 'amountDue',
        sorter: (a, b) => a.amountDue - b.amountDue,
        render: (text, record, index) => {
            return (<div style={{}}>{record.currency.toUpperCase()} {text.toFixed(2)}</div>)
        }
    },
    {
        title: 'Paid',
        dataIndex: 'amountPaid',
        key: 'amountPaid',
        sorter: (a, b) => a.amountPaid - b.amountPaid,
        render: (text, record, index) => {
            return (<div style={{}}>{record.currency.toUpperCase()} {text.toFixed(2)}</div>)
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record, index) => {
            if (text === "paid")
                return (<Tag
                    color="blue"
                    bordered={false}
                    style={{
                        fontWeight: 700
                    }}>Paid</Tag >)
            else if (text === "draft")
                return (<Tag
                    color="grey"
                    bordered={false}
                    style={{
                        fontWeight: 700
                    }}>Draft</Tag >)
            else if (text === "open")
                return (<Tag
                    color="green"
                    bordered={false}
                    style={{
                        fontWeight: 700
                    }}>Open</Tag >)
            else if (text === "uncollectible")
                return (<Tag
                    color="orange"
                    bordered={false}
                    style={{
                        fontWeight: 700
                    }}>Uncollectible</Tag >)
            else if (text === "void")
                return (<Tag
                    color="purple"
                    bordered={false}
                    style={{
                        fontWeight: 700
                    }}>Void</Tag >)
        }
    },
    {
        dataIndex: 'actions',
        key: 'actions',
        align: "right",
    },
]


export function Invoices({ onUpdate, values, kernel }) {
    const [loaded, setLoaded] = useState(true)

    const [list, setList] = useState([])

    useEffect(() => {

        async function fetchList(permissions) {
            var response = await kernel.api.get("/user/invoices/list")
            if (!response || response.error) {
                notification.error({
                    message: `Can not fetch invoices`,
                    description: response?.error || null
                })
                return
            }
            for (let item of response.data) {
                item.actions =
                    <>
                        <Space.Compact size="small">
                            {item.status === "open" ?
                                <Button type="primary" onClick={() => {
                                    window.open(item.viewURL)
                                }}>Pay now</Button>
                                :
                                <Button onClick={() => {
                                    window.open(item.downloadURL)
                                }}>Download</Button>
                            }
                        </Space.Compact>
                    </>
            }
            setList(response.data)
        }

        fetchList()
    }, [loaded])

    return (
        <>
            <DiventryBlock>
                <Table
                    size="small"
                    dataSource={list}
                    columns={columns}
                    tableLayout="fixed"
                />
            </DiventryBlock>
        </>
    )
}
