import { useState, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import {
    Button,
    Select,
    Form,
    Input,
    Col,
    Space,
    Spin,
    theme,
    notification
} from 'antd';

import { UtilsInputLang } from "../../Templates/Utils";

import { DiventryLayoutSimple } from '../../Templates/LayoutSimple'

const item = {
    wrapperCol: { span: 16, offset: 8 },
}

const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
}

export function Register({ kernel }) {
    let params = useParams();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState({})
    const [help, setHelp] = useState({})
    const [confirm, setConfirm] = useState(false)

    const onFinish = async (values) => {
        setLoading(true)
        const response = await kernel.api.post('/user/register', values)
        if (response.error) {
            notification.error({
                message: `Sign in error`,
                description: <>{response.error}</>
            })

            if (response.fields) {
                const newStatus = {}
                const newHelp = {}
                for (var field in values) {
                    newStatus[field] = "success"
                    newHelp[field] = null
                }
                for (var field of response.fields) {
                    newStatus[field[0]] = "error"
                    newHelp[field[0]] = field[1]
                }

                setStatus(newStatus)
                setHelp(newHelp)
            }
            setLoading(false)
            return
        }
        notification.success({
            message: `Thanks for the registration`
        })
        setLoading(false)
        setConfirm(true)
    }

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    }


    return (
        <DiventryLayoutSimple>
            {confirm === true ?
                <div style={{ fontSize: "1.4em", textAlign: "center" }}>
                    <h1>Thanks!</h1>
                    <p style={{ width: "100%" }}>
                        You will receive an email containing a confirmation code for your account. Please use this code to complete the confirmation process.
                    </p>
                </div>
                : null}

            {confirm === false ?
                <Spin tip="Loading..." spinning={loading}>
                    <Form
                        {...layout}
                        name="basic"

                        style={{
                            // textAlign: "left"
                            // maxWidth: 600,
                        }}
                        initialValues={{
                            lang: navigator.language.split("-")[0]
                            // remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item validateStatus={status.firstName} help={help.firstName} label="First name" name="firstName">
                            <Input />
                        </Form.Item>

                        <Form.Item validateStatus={status.lastName} help={help.lastName} label="Last name" name="lastName">
                            <Input />
                        </Form.Item>

                        <Form.Item validateStatus={status.lang} help={help.lang} label="Language" name="lang">
                            <UtilsInputLang />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            validateStatus={status.email}
                            help={help.email}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>



                        <Form.Item
                            label="Password"
                            name="password1"
                            validateStatus={status.password1}
                            help={help.password1}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item
                            label="Password Check"
                            name="password2"
                            validateStatus={status.password2}
                            help={help.password2}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password check!',
                                },
                            ]}
                        >
                            <Input.Password autoComplete="new-password" />
                        </Form.Item>

                        <Col span={16} offset={8}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Register
                                </Button>
                                <span style={{ fontSize: "0.9em" }}>
                                    <Link to="/session/login">Go to login page</Link>
                                </span>
                            </Space>

                        </Col>
                    </Form>

                </Spin>
                : null}
        </DiventryLayoutSimple>
    );
}
