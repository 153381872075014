
import { useCallback } from "react"

import {
    Table,
    theme
} from 'antd';

import {
    SearchOutlined,
    LeftOutlined,
    SyncOutlined,
    RightOutlined
} from '@ant-design/icons'

import {
    LogsColors
} from '../../../Plans/Templates/LogsStream'
import { ABList } from "../../../Plans/Templates/ABList";

export function Allowlist({ kernel }) {
    return (<ABList kernel={kernel} list="allow" color={LogsColors.passed} type="profileId" />)
}

export function Blocklist({ kernel }) {
    return (<ABList kernel={kernel} list="block" color={LogsColors.blocking} type="profileId" />)
}