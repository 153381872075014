import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { AutoComplete } from 'antd';
import { ThreatDBTag } from './Tag';


export function ThreatDBSearch({ kernel, value }) {
    const navigate = useNavigate()

    const [searchText, setSearchText] = useState(value)
    const [searchOptions, setSearchOptions] = useState([])
    const [searchType, setSearchType] = useState("")

    // useEffect(() => {
    //     setSearchText(value)
    //     setSearchOptions([])
    //     setSearchType("")
    // }, [value]);

    useEffect(() => {
        const delayDebounce = setTimeout(async () => {
            const ret = await kernel.api.get(`/threatdb/lookup?query=${searchText}`);
            if (ret.error) {
                setSearchOptions([]);
                return;
            }

            const newOptions = [];
            for (var key of ret.data.keys) {
                const tagsShow = key.tags.map((tag) => {
                    return (<ThreatDBTag key={`${key.value}-${tag}`} kernel={kernel} tag={tag} />);
                });

                newOptions.push({
                    value: key.value,
                    label: <>{key.value} {tagsShow}</>
                });
            }
            setSearchType(ret.data.type);
            setSearchOptions(newOptions);
        }, 500);

        return () => clearTimeout(delayDebounce);
    }, [searchText]);

    return (<AutoComplete
        options={searchOptions}
        style={{
            width: 300,
        }}
        popupMatchSelectWidth={500}
        value={searchText}
        onSelect={((data) => {
            navigate(`/threatdb/show/${searchType}?index=${data}`);
        })}
        onSearch={(text) => {
            setSearchText(text);
        }}
        placeholder="IP, network or domain" />);
}
