
import { useCallback } from "react"

import {
    Table,
    theme
} from 'antd';

import {
    SearchOutlined,
    LeftOutlined,
    SyncOutlined,
    RightOutlined
} from '@ant-design/icons'

import {
    LogsColors
} from '../../../Plans/Templates/LogsStream'

import { ABList } from "../../../Plans/Templates/ABList";
import { NotAvailable } from "./index"

export function Allowlist({ kernel, device }) {

    return (
        <>
            {device.deviceManagement === "mdm" ?

                <NotAvailable></NotAvailable>
                :
                <ABList kernel={kernel} list="allow" color={LogsColors.passed} type="deviceId" />
            }
        </>
    )
}

export function Blocklist({ kernel, device }) {
    return (
        <>
            {device.deviceManagement === "mdm" ?

                <NotAvailable></NotAvailable>
                :
                <ABList kernel={kernel} list="block" color={LogsColors.blocking} type="deviceId" />
            }
        </>
    )
}
