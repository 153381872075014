
import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"

import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber
} from 'antd'
import {
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons'

import { DiventryBlock } from '../../../../Templates/Layout'

import { DiventryPermissions } from '../../../../Templates/Permissions'

const permissions = {
    admin: "Administrator",
    read: "Reader",
}

export function Permissions({ kernel, forwardId }) {
    return (
        <DiventryBlock>
            <DiventryPermissions
                kernel={kernel}
                forwardId={forwardId}
                permissionKey="dohzelProfile"
                permissions={permissions}
            />
        </DiventryBlock>
    )
}
