// services
import serviceAPI from './services/api'
import serviceTAGS from './services/tags'
import serviceSession from './services/session'


export class Events {
    constructor() {
        this._acall = {}
    }

    async emit(ev, ...argv) {
        if (!this._acall[ev]) return;
        const list = this._acall[ev];
        for (let call of list) await call(...argv);
    }

    on(ev, cb) {
        if (!this._acall[ev]) this._acall[ev] = []
        this._acall[ev].push(cb)
    }

    remove(ev, cb) {
        if (!this._acall[ev]) return
        const newList = []
        for (var item of this._acall[ev]) {
            if (item !== cb)
                newList.push(cb)
        }
        this._acall[ev] = newList
    }
}

export class Kernel extends Events {
    constructor(config) {
        super()
        this.config = config

        // immediate url parse
        this.url = new window.URL(window.location)

        console.log("Kernel constructed")
    }

    debug(message) {
        console.log(message)
    }

    info(message) {
        this.log(message)
    }

    error(message) {
        this.log(message)
    }

    warning(message) {
        this.log(message)
    }

    async log(message) {
        await this.emit("log", message)
        console.log(message)
    }

    async init() {
        const kernel = this
        if (kernel.loaded === true) return
        kernel.loaded = true

        // manage fatal
        kernel.isFatal = false
        const onFatal = kernel.fatal
        function fatal(msg) {
            kernel.isFatal = true
            onFatal(msg)
        }
        kernel.fatal = fatal

        // await servicePKI(kernel)
        await serviceSession(kernel)
        await serviceAPI(kernel)
        await serviceTAGS(kernel)
        
        kernel.log("Kernel initialized")

        // await (new Promise((accept) => {
        //     function test() {
        //         if (output.length > 0) setTimeout(test, 500)
        //         else {
        //             kernel.log(" ")
        //             setTimeout(accept, 500)
        //         }
        //     }
        //     setTimeout(test, 100)
        // }))

    }
}


