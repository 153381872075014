import { useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import logoColors from './logo.png'
import { DiventryLayoutImages } from './Layout'

export function DiventryLayoutSimple({ children }) {
    const [loaded, setLoaded] = useState(true);

    const [bg, setBg] = useState(null);

    useEffect(() => {
        setBg(DiventryLayoutImages.random());
    }, [loaded]);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#7eb022",
                    colorInfo: "#7eb022"
                },
            }}
        >
            <div style={{
                // background: "red",
                height: "100vh"
            }}>
                <div style={{
                    backgroundImage: `url("/wall/${bg}")`,
                    height: "100%",
                    position: "relative",
                    // opacity: "0.65",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}>
                    <div style={{
                        fontFamily: 'Montserrat',
                        position: "fixed",
                        textAlign: "center",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                    }}>
                        <div>
                            <img src={logoColors} width={200} />
                        </div>
                        <div style={{
                            width: 150,
                            margin: "0 auto",
                            borderRadius: 5,
                            fontWeight: "900",
                            padding: 3,
                            color: "#f1f3f3",
                            fontSize: "20px",
                            background: "rgba(126,176,34,0.8)"
                        }}>HAFNOVA</div>
                        <br />
                        <p style={{
                            borderRadius: 5,
                            background: "rgba(229,228,229,0.9)",
                            padding: 20
                        }}>
                            {children}
                        </p>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
}
