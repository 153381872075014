import { useEffect, useState } from "react"

import {
    Button,
    Input,
    Table,
    Alert,
    notification,
    Space,
    Popconfirm,
    Tooltip
} from 'antd'

import { CheckCircleTwoTone, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import {
    DiventryBlock
} from '../../Templates/Layout'


export function Share({ onUpdate, values, kernel }) {
    const [loading, setLoading] = useState(false)

    const generate = async (form) => {
        setLoading(true)
        var response = await kernel.api.get("/user/shareCode")
        if (!response || response.error) {
            notification.error({
                message: `Can not generate share code`,
                description: response?.error || null
            })
            setLoading(false)
            return
        }

        notification.success({
            message: `Share code`,
            description: "New share code has been generated"
        })
        values.shareCode = response.data.shareCode
        onUpdate(values)
        setLoading(false)
    }

    return (
        <>
            <DiventryBlock>
                <div style={{
                    padding: 20,
                    width: "100%",
                    fontSize: 50,
                    fontWeight: 800
                }}>
                    <center>

                        {values.shareCode ?
                            <>
                                {values.shareCode}<br />
                                <Button type="primary" loading={loading} onClick={generate}>Regenerate</Button>
                            </>
                            :
                            <>
                                <Button type="primary" loading={loading} onClick={generate}>Generate one</Button>
                            </>}


                        <p style={{
                            fontSize: 12,
                            padding: 20,
                            fontWeight: 600
                        }}>
                            The share code enables you to identify yourself to others on Hafnova. When a colleague wants to grant you permissions, share this code with them.
                        </p>
                    </center>

                </div>

            </DiventryBlock>
        </>
    )
}
