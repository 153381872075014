
import { Link, useParams } from "react-router-dom"
import { Content } from '../../../../Templates'
import {
    Tabs,
    Switch,
    InputNumber
} from 'antd'



import { DiventryMenu } from '../../../../Templates/Menu'
import {
    DiventryLayoutCyberIntel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryTitle
} from '../../../../Templates/Layout'

import { ConfigurationRules } from './ConfigurationRules'
import { ConfigurationGeneral } from './ConfigurationGeneral'
import { ConfigurationAdvanced } from './ConfigurationAdvanced'

export function Configuration({ kernel }) {
    // for navigation
    const { id } = useParams()

    return (<>
        <Tabs
            type="card"
            defaultActiveKey="list-info"
            items={[
                {
                    label: "General",
                    key: "general",
                    children: <div style={{}}>
                        <ConfigurationGeneral kernel={kernel} />
                    </div>
                },
                {
                    label: "Rules based detection",
                    key: "rules",
                    children: <ConfigurationRules kernel={kernel} id={id} />
                },
                {
                    label: "Notifications",
                    key: "notification",
                    children: <ConfigurationAdvanced kernel={kernel} id={id} />
                },
                {
                    label: "Advanced Settings",
                    key: "advanced",
                    children: <ConfigurationAdvanced kernel={kernel} id={id} />
                },
            ]}
        />
    </>)
}