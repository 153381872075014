export default async function init(kernel) {
    kernel.log("Loading Tags")

    kernel.tags = {
        list: {},
    }

    kernel.tags.update = async () => {
        const ret = await kernel.api.get('/threatdb/tags/list')
        if (!ret.error) {
            kernel.tags.list = {}
            for (var item of ret.data)
                kernel.tags.list[item.name] = item
        }
        setTimeout(kernel.tags.update, 5 * 60 * 1000)
    }
    await kernel.tags.update()
}

