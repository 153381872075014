
import { useEffect, useState, useCallback } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"

import {
    Tabs,
    Space,
    Button,
    Dropdown,
    notification
} from 'antd';

import {
    CaretDownOutlined
} from '@ant-design/icons'

import {
    DiventrySide
} from '../../../../Templates/Layout'

import { General } from './General'
import { Advanced } from './Advanced'
import { Security } from './Security'
import { Allowlist, Blocklist } from './ABList'

import {
    CloudSyncOutlined
} from '@ant-design/icons'

const allPages = {
    'general': {
        key: 'general',
        title: "General",
        ref: General
    },
    'security': {
        key: 'security',
        title: "Security policies",
        ref: Security
    },
    'allowlist': {
        key: 'allowlist',
        title: "Allow list",
        ref: Allowlist
    },
    'blocklist': {
        key: 'blocklist',
        title: "Block list",
        ref: Blocklist
    },
    'advanced': {
        key: 'advanced',
        title: "Advanced",
        ref: Advanced
    },
};

export function NotAvailable() {
    return (
        <div style={{
            padding: 20,
            width: "100%",
        }}>
            <center>
                <p style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 60
                }}>
                    <CloudSyncOutlined />
                </p>

                <p style={{
                    fontSize: 20,
                    fontWeight: 700
                }}>
                    This device is under management system
                </p>

                <p style={{
                    paddingLeft: "20%",
                    paddingRight: "20%",
                    fontSize: 14,
                    fontWeight: 500
                }}>
                    This device is managed directly by the profile, inheriting its security configurations.
                    This includes blocking mode, security policies, authorization and blocking lists.
                    In other words, it benefits from the parameters defined in the profile to guarantee
                    optimum security levels.
                </p>
            </center>

        </div>
    )
}


export function Settings({ kernel, updateDevice }) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const [loaded, setLoaded] = useState(true)
    const [page, setPage] = useState(null)
    const [content, setContent] = useState(null)
    const [pages, setPages] = useState(allPages)

    useEffect(() => {
        if (params.page1 !== "settings")
            return

        if (!params.page2) {
            navigate(`/dohzel/device/${params.id}/settings/general`)
        }
        else {

            async function fetch() {
                const raw = await kernel.api.get(`/dohzel/device/get?id=${params.id}`)
                if (raw.error || !raw.data)
                    return (navigate("/dohzel/profile"))

                const el = pages[params.page2]
                if (!el)
                    return (navigate(`/dohzel/device/${params.id}/settings/general`))
                setPage(el)
                setContent(<el.ref kernel={kernel} updateDevice={() => {
                    setLoaded(!loaded)
                    updateDevice()
                }} device={raw.data} />)
                if(raw.data?.deviceManagement == 'mdm') {
                    // var _pages = pages;
                    // _pages.
                    setPages({
                        ...pages, 
                        security: {...pages?.security, disabled: true},
                        allowlist: {...pages?.allowlist, disabled: true},
                        blocklist: {...pages?.blocklist, disabled: true},
                    })
                }
            }
            fetch()


        }
    }, [params, loaded])

    return (
        <DiventrySide
            card={true}
            selectedKeys={[params.page2]}
            menu={Object.values(pages).map((item) => {
                return ({
                    key: item.key,
                    label: item.title,
                    disabled: item.disabled ?? false,
                })
            })}
            onClick={(e) => {
                navigate(`/dohzel/device/${params.id}/settings/${e.key}`)
            }}
        >
            {content}
        </DiventrySide>
    )
}