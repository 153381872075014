import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useSearchParams  } from "react-router-dom";
// import { LibAPIdisconnect, LibAPIpost, LibAPItoken, LibAPIgetRedirect } from "../../../lib/LibAPI";
import {
    Button,
    Select,
    Form,
    Input,
    Col,
    Space,
    Spin,
    theme,
    notification
} from 'antd';

import { DiventryLayoutSimple } from '../../Templates/LayoutSimple'

const item = {
    wrapperCol: { span: 16, offset: 8 },
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}


export function Login({ kernel }) {
    let params = useParams();

    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams()
    
    // Special implementation for jumpToken in order to force
    // UI to the set the internal token and move to /
    // used by SSO
    useEffect(() => {
        const rebound = searchParams.get("jumpToken")
        if (rebound) {
            setLoading(true)

            const key = rebound.split(">")
            key[0] = Buffer.from(key[0], 'hex').toString('base64')
            key[1] = Buffer.from(key[1], 'hex').toString('base64')
            key[2] = Buffer.from(key[2], 'hex').toString('base64')
            const token = key.join(">")

            kernel.session.store(token) // ca n'existe plus 

            setTimeout(() => {
                setLoading(false)
                navigate("/")
            }, 1000)
        }
    }, [loaded])

    const onFinish = async (values) => {
        setLoading(true)

        const ret = await kernel.session.login(values.email, values.password)
        if (ret) {
            notification.error({
                message: ret
            })
            setLoading(false)
            return
        }
        notification.success({
            message: `Your are logged, welcome!`
        })
        setLoading(false)
        navigate("/dohzel/profile")
    }

    const onFinishFailed = async (errorInfo) => {
        // console.log('Failed:', errorInfo);
    }

    return (
        <DiventryLayoutSimple>

            <Spin tip="Loading..." spinning={loading}>
                <Form
                    {...layout}
                    name="basic"
                    style={{
                        // maxWidth: 600,
                    }}
                    initialValues={{
                        // remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Col span={16} offset={8}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Sign in
                            </Button>
                            <span style={{ fontSize: "0.9em" }}>
                                <Link to="/session/forgot">Forgot password?</Link>
                            </span>
                        </Space>
                    </Col>

                    <Col span={16} offset={5} style={{ paddingTop: 20, fontSize: "0.9em" }}>
                        <Link to="/session/register">No account yet? Create one for free!</Link>
                    </Col>
                </Form>
            </Spin>

        </DiventryLayoutSimple>
    );
}