
import { useEffect, useState, useRef } from "react"
import { useParams, useLocation, useNavigate, Link } from "react-router-dom"

import {
    Tabs,
    Space,
    Button,
    Dropdown,
    Form,
    Modal,
    Input,
    notification
} from 'antd';

import {
    CaretDownOutlined
} from '@ant-design/icons'


import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutDohzel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventrySide
} from '../../../Templates/Layout'

import Logo from '../../../Images/logoSmall.png'

import Brands from '../Brands/Index'

import { Settings } from './Settings'
import { Statistics } from './Statistics'
import { Logs } from './Logs'
// import { Devices } from './Devices'
// import { Statistics } from './Statistics'

export function Null() {
    return (<>vcfevsrvf</>)
}

const pages = {
    'statistics': {
        key: 'statistics',
        title: "Statistics",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Statistics
    },
    'logs': {
        key: 'logs',
        title: "Logs",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Logs
    },
    'settings': {
        key: 'settings',
        title: "Settings",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Settings
    },
    // 'installation': {
    //     key: 'installation',
    //     title: "Installation",
    //     description: "Your personnal information",
    //     ref: Null
    // },
}


export function Device({ kernel }) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    var ignore = false
    const [back, setBack] = useState(null)
    const [loaded, setLoaded] = useState(true)
    const [tabs, setTabs] = useState([])

    const [profile, setProfile] = useState({})
    const [device, setDevice] = useState({})

    const [modalCreate, setModalCreate] = useState(false)
    const [theForm] = Form.useForm()

    useEffect(() => {
        if (ignore)
            return
        setBack(localStorage.getItem("deviceBack"))

        async function fetch() {
            var response = await kernel.api.get(`/dohzel/device/get?id=${params.id}`)
            if (response.error) {
                notification.error({
                    message: `Error fetching information`,
                    description: response.error
                })

                return
            }

            setDevice(response.data)

            response = await kernel.api.get(`/dohzel/profile/get?id=${response.data.profileId}`)
            if (response.error) {
                notification.error({
                    message: `Error fetching information`,
                    description: response.error
                })

                return
            }
            setProfile(response.data)

            setTabs(Object.values(pages))
        }


        fetch()

        return (() => {
            ignore = true
        })
    }, [params, loaded])



    async function updateDevice() {
        setLoaded(!loaded)
    }

    return (
        <DiventryLayoutDohzel>

            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                noStyle={true}
                title={<Brands.BrandedDevice device={device} font={24} size={60} />}
                description={`DoHzel device from ${profile.name}`}
            />
            <DiventryLayoutContent>
                {back ?
                    <DiventryBlock>
                        <Button onClick={() => navigate(back)}>Return to profile</Button>
                    </DiventryBlock>
                    : null}
                <DiventryBlock>
                    <Tabs
                        type="card"
                        activeKey={params.page1}
                        onChange={(tab) => {
                            navigate(`/dohzel/device/${params.id}/${tab}`)
                        }}
                        items={tabs.map((item) => {
                            return ({
                                key: item.key,
                                label: item.title,
                                children: <div style={{ paddingTop: 10 }}>
                                    <item.ref kernel={kernel} device={device} updateDevice={updateDevice} />
                                </div>
                            })
                        })}
                    />
                </DiventryBlock>


            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>

        </DiventryLayoutDohzel>
    )
}