
import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"
import { Content } from '../../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber
} from 'antd'
import {
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons'




const permissions = {
    admin: "Administrator",
    read: "Reader",
    write: "Write",
    download: "Download"
}

export function Permissions({ kernel, list }) {
    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)

    const [table, setTable] = useState([])

    const [modalEdit, setModalEdit] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        async function fetch() {
            const raw = await kernel.api.get(`/threatdb/list/permissions/list?list=${list}`)
            if (raw.error || !raw.data) {
                notification.error({
                    message: 'Error getting API',
                    description: raw.error
                })
                return
            }
            setTable(raw.data)
        }
        fetch()
    }, [loaded])

    const handleOk = async () => {
        const data = form.getFieldsValue()
        var endpoint = `/threatdb/list/permissions/create`
        if (modalEdit === true) {
            delete data.user
            delete data.list
            endpoint = `/threatdb/list/permissions/update`
        }
        else
            data.list = list

        const ret = await kernel.api.post(endpoint, data)
        if (!ret || ret.error) {
            notification.error({
                message: modalEdit === true ? 'Error updating permission' : 'Error adding permission',
                description: ret.error
            })
            return
        }
        notification.success({
            message: modalEdit === true ?
                'User permissions has been updated'
                :
                'New user permission added'
        })

        setLoaded(!loaded)
        setIsModal(false)
    }
    const handleCancel = () => {
        setIsModal(false)
    }

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text, record, index) => {
                return (<strong>{text}</strong>)
            }
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record, index) => {
                return (new Date(text).toLocaleString())
            }
        },
        {
            title: 'Access',
            dataIndex: 'roles',
            key: 'roles',
            render: (text, record, index) => {
                return (record.roles.map((item) => {
                    return (<Tag key={`access-${record.list}-${item}`}>{permissions[item]}</Tag>)
                }))
            }
        },
        {
            dataIndex: 'id',
            align: "right",
            render: (text, record, index) => {
                return (

                    <Space.Compact size="small">
                        <Popconfirm
                            placement="left"
                            title="Delete this user permission"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={async () => {
                                const ret = await kernel.api.post(
                                    `/threatdb/list/permissions/remove`,
                                    { id: record.id }
                                )
                                if (ret.error) {
                                    notification.error({
                                        message: 'Error deleting key',
                                        description: ret.error
                                    })
                                    return
                                }

                                notification.success({
                                    message: 'Entry was deleted'
                                })
                                setLoaded(!loaded)
                            }}
                        >
                            <Button ><DeleteOutlined /></Button>
                        </Popconfirm>

                        <Button onClick={() => {
                            setModalEdit(true)
                            form.resetFields()
                            form.setFieldsValue(record)
                            setIsModal(true)
                        }}><SettingOutlined /></Button>
                    </Space.Compact>
                )

            }
        }
    ]

    return (
        <>
            <Modal
                title={modalEdit === true ? "Update list permission" : "Add list permission"}
                open={isModal}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
            >
                <Form
                    form={form}
                >
                    {modalEdit === true ?
                        <Form.Item label="Rule ID" name="id" hidden={true}>
                            <Input disabled={true} />
                        </Form.Item>
                        : null}


                    <Form.Item label={modalEdit === true ? "User" : "User Share Code"} name="user">
                        <Input disabled={modalEdit} />
                    </Form.Item>

                    <Form.Item label="User's roles" name="roles">
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Select permissions type"
                            options={Object.keys(permissions).map((item) => {
                                return ({ value: item, label: permissions[item] })
                            })}
                        />
                    </Form.Item>

                </Form>
            </Modal>

            <p>
                <Button
                    type="primary"
                    onClick={() => {
                        setModalEdit(false)
                        form.resetFields()
                        setIsModal(true)
                    }}
                >Add permission</Button>
            </p>
            <Table
                pagination={false}
                dataSource={table}
                columns={columns} />
        </>


    )
}