import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import {
    Button,
    Tag, Space,
    Descriptions,
    Form,
    Input,
    Select,
    notification
} from 'antd'

import { DiventryBlock } from '../../../../Templates/Layout'


export function General({ kernel, updateDevice, device }) {
    // main document
    const [edit, setEdit] = useState(false);

    // for source addition
    const [isModalSource, setIsModalSource] = useState(false);
    const [titleModalSource, setTitleModalSource] = useState("");
    const [sourceForm] = Form.useForm();

    // for update
    const [isModalUpdate, setIsModalUpdate] = useState(false);
    const [updateForm] = Form.useForm();

    return (
        <>
            <DiventryBlock >
                <Form form={sourceForm}>
                    <Descriptions
                        bordered
                        column={{
                            xxl: 2,
                            xl: 2,
                            lg: 2,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item span={2} label="Device Name">
                            {edit === true ?
                                <Form.Item name="name" noStyle={true}>
                                    <Input />
                                </Form.Item>
                                :
                                <strong>{device.name}</strong>}
                        </Descriptions.Item>

                        <Descriptions.Item span={2} label="DNS over HTTPS (DoH)">
                            {device.contactDOH}
                        </Descriptions.Item>

                        <Descriptions.Item span={2} label="DNS over TLS (DoT)">
                            {device.contactDOT}
                        </Descriptions.Item>

                        <Descriptions.Item label="Creation date">
                            {new Date(device.createdAt).toLocaleString()}
                        </Descriptions.Item>

                        <Descriptions.Item label="Last update">
                            {new Date(device.updatedAt).toLocaleString()}
                        </Descriptions.Item>

                    </Descriptions>
                </Form>
            </DiventryBlock>

            <DiventryBlock>
                <Space.Compact>
                    {edit === true ?
                        <>
                            <Button type="primary" onClick={async () => {
                                const data = sourceForm.getFieldsValue();
                                data.id = device.id;
                                const ret = await kernel.api.post(`/dohzel/device/update`, data);
                                if (!ret) {
                                    notification.error({
                                        message: 'Service unavailable'
                                    });
                                    return;
                                }
                                if (ret.error) {
                                    notification.error({
                                        message: ret.error
                                    });

                                    if (ret.fields) {
                                        const fields = [];
                                        for (var key in ret.fields) {
                                            const message = ret.fields[key];
                                            const k = key.split(".");
                                            fields.push({ name: k[0], errors: message });
                                        }

                                        sourceForm.setFields(fields);
                                    }

                                    return;
                                }

                                notification.success({
                                    message: "Device information saved"
                                });

                                setEdit(false)
                                updateDevice()
                            }}>Save</Button>
                            <Button onClick={() => {
                                setEdit(false);
                            }}>Cancel</Button>
                        </>
                        :
                        <Button type="primary" onClick={() => {
                            sourceForm.resetFields();
                            sourceForm.setFieldsValue(device);
                            setEdit(true);
                        }}>Edit</Button>}
                </Space.Compact>
            </DiventryBlock>
        </>
    );
}
