import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import Statistic from "../../../../Templates/Statistic"

import {
    Select,
    Card,
    Flex,
    Empty,
    Row,
    Col
} from 'antd';

import {
    DiventryBlock
} from '../../../../Templates/Layout'

const periodes = [
    { value: 'last24h', label: 'last 24 hours', global: 'total24h' },
    { value: 'last30d', label: 'last 30 days', global: 'total30d' },
    { value: 'last90d', label: 'last 90 days', global: 'total90d' },
]

const initialGlobaux = [
    {
        name: 'requests',
        value: 0,
        color: '#000000'
    },
    {
        name: 'blocked requests',
        value: 0,
        color: '#ff0000cf'
    },
    {
        name: 'of blocked requests',
        value:  0,
        color: '#777777',
        unit: '%'
    }
];

const statColors = {
    all: '#3498db', 
    blocking: "#e74c3c", 
    detecting: '#f1c40f', 
    passing: '#16a085'
}

HC_exporting(Highcharts);

export function Statistics({ kernel }) {

    const { id } = useParams();

    const [tags, setTags] = useState([
        { value: 'all', label: 'All tags' }
    ])

    const [globalStats, setGlobalStats] = useState(initialGlobaux);

    const [filters, setFilters] = useState({
        tag: "all",
        periode: 'last30d'
    })
    
    const [dataStat, setDataStat] = useState([]);

    useEffect(() => {
        asyncFetch();
    }, []);

    const asyncFetch = (periode=filters?.periode) => {
        (async () => {
            const global = periodes.find((period) => period.value == periode)?.global;
            const globaux = await kernel.api.get(`/dohzel/device/profile/stats/${global}?id=${id}`)
            if (globaux.error) {
                console.log(globaux.error)
            }

            const stats = await kernel.api.get(`/dohzel/device/profile/stats/${periode ?? filters?.periode}?id=${id}`)
            if (stats.error) {
                console.log(stats.error)
            }

            handleGlobalStats(globaux);
            handleAreaStats(stats);
        })()
    };

    const handleGlobalStats = (globaux) => {
        if (globaux?.data) {
            const _globalStats = [...initialGlobaux];
            _globalStats[0].value = globaux?.data?.values?.reduce((acc, item) => acc + item?.[1], 0) ?? 0;
            _globalStats[1].value = globaux?.data?.values?.reduce((acc, item) => acc + item?.[2], 0) ?? 0;
            _globalStats[2].value = ((globaux?.data?.values?.reduce((acc, item) => acc + item?.[2], 0) ?? 0) / (globaux?.data?.values?.reduce((acc, item) => acc + item?.[1], 0) ?? 1) * 100).toFixed(2) ?? 0;
            setGlobalStats(_globalStats)
        }
        else {
            setGlobalStats([])
        }
    }

    const handleAreaStats = (stats) => {
        if (stats?.data) {
            const data = stats?.data?.columns?.map((item, index) => (
                {
                    name: item,
                    color: statColors?.[item] ?? null,
                    data: stats?.data?.values?.map(value => index == 0 ? (new Date(value?.[index])?.toLocaleString()) : value?.[index])
                }
            ));
            setDataStat(data);
        } 
        else {
            setDataStat([]);
        }  
    }

    useEffect(() => {
        (async () => {
            const response = await kernel.api.get('/dohzel/tags')
            if (response.error) {
                return;
            }
            setTags([
                { value: 'all', label: 'All tags' },
                ...response.data?.map(tag => ({ value: tag.name, label: tag.name }))
            ])
        })()
    }, []);

    return (
        <>
            <DiventryBlock>
                <Flex justify={'space-between'} align={'flex-start'} style={styles.filters}>
                    <Select
                        value={filters.tag}
                        style={{ minWidth: 200 }}
                        onChange={(key) => {
                            setFilters({ ...filters, device: key })
                        }}
                        disabled={true}
                        options={tags}
                    />

                    <Select
                        value={filters.periode}
                        style={{ minWidth: 200 }}
                        onChange={(key) => {
                            setFilters({ ...filters, periode: key });
                            asyncFetch(key);
                        }}
                        options={periodes}
                    />
                </Flex>

                {
                    globalStats?.length ? 
                    <Card gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={styles.statContainer}>
                        {
                            globalStats.map(stat => (
                                <Card.Grid style={{ width: `${(100 / globalStats?.length) - 2}%`, margin: '1%' }}>
                                    <h1 style={{ ...styles.statName, color: stat.color }}>{stat.value} {stat.unit}</h1>
                                    <span style={styles.statValue}>{stat.name}</span>
                                </Card.Grid>
                            ))
                        }
                    </Card>
                    :''
                }
                <Row>
                    <Col span={16}>
                        <Card gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ ...styles.statContainer, textAlign: 'left' }}>
                            <Statistic
                                kernel={kernel}
                                endpoint={`/dohzel/device/profile/stats/${filters?.periode}?id=${id}`}
                                type='area'
                                statTypes={{
                                    all: '#3498db', 
                                    blocking: "#e74c3c",
                                    bypassing: 'purple',  
                                    detecting: '#f1c40f', 
                                    passing: '#16a085'
                                }}
                                disabled={['passing']}
                                exclude={['bypassing']}
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ ...styles.statContainer, textAlign: 'left' }}>
                            <Statistic
                                kernel={kernel}
                                endpoint={`/dohzel/device/profile/tags/${periodes.find((period) => period.value == filters.periode)?.global}?id=${id}`}
                                statCategoryName={'tags'}
                                statName={'Tag'}
                                type='pie'
                            />
                        </Card>
                    </Col>
                </Row>
            </DiventryBlock>

        </>
    )
}

const styles = {
    filters: {
        marginTop: 20
    },
    statContainer: {
        marginTop: 25,
        textAlign: 'center'
    },
    statName: {
        marginBottom: '-0.2em',
        fontWeight: 800
    },
    statValue: {
        color: '#777777',
        fontSize: '18px'
    }
}