import { SecurityPolicies } from "../../../Plans/Templates/SecurityPolicies";
import { NotAvailable } from "./index"

export function Security({ kernel, device, type = "device" }) {
    return (
        <>
            {device.deviceManagement === "mdm" ?

                <NotAvailable></NotAvailable>
                :
                <SecurityPolicies kernel={kernel} type="device" />
            }
        </>
    )
}
