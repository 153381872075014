import CountryList from "countries-list";
import {
    Select,
} from 'antd';

CountryList.languagesAllSelect = []
for (var key in CountryList.languagesAll) {
    const lang = CountryList.languagesAll[key]
    lang.code = key
    CountryList.languagesAllSelect.push({
        value: lang.code,
        label: lang.native
    })
}

CountryList.countriesSelect = []
for (var key in CountryList.countries) {
    const item = CountryList.countries[key]
    item.code = key
    CountryList.countriesSelect.push({
        value: item.code,
        label: item.name
    })
}

const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  

export function UtilsInputLang(props) {
    return (
        <Select
            {...props}
            // defaultValue={navigator.language.split("-")[0]}
            options={CountryList.languagesAllSelect}
            filterOption={filterOption}
            showSearch
            filterSort={(country1, country2) => (country1.label?.toUpperCase() < country2.label?.toUpperCase()) ? -1 : 1}
        />
    )
}

export function UtilsInputCountry(props) {
    return (
        <Select
            {...props}
            // defaultValue={navigator.language.split("-")[0]}
            options={CountryList.countriesSelect}
            filterOption={filterOption}
            showSearch
            filterSort={(country1, country2) => (country1.label?.toUpperCase() < country2.label?.toUpperCase()) ? -1 : 1}
        />
    )
}

export function UtilsHidePart(apiKey, startVisibleChars, endVisibleChars, maskChar = '*', noRepeat = false) {
    const apiKeyLength = apiKey.length;
    if (apiKeyLength <= startVisibleChars + endVisibleChars)
        return apiKey;
    if (noRepeat === false) {
        const visiblePart = apiKey.slice(0, startVisibleChars) + maskChar.repeat(apiKeyLength - startVisibleChars - endVisibleChars) + apiKey.slice(apiKeyLength - endVisibleChars);
        return visiblePart;
    }
    const visiblePart = apiKey.slice(0, startVisibleChars) + maskChar + apiKey.slice(apiKeyLength - endVisibleChars);
    return visiblePart;
}

export function UtilsConvertTime(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24)) % 30;
    const months = Math.floor(milliseconds / (1000 * 60 * 60 * 24 * 30));

    const result = [];
    if (months > 0) {
        result.push(months + (months === 1 ? ' month' : ' months'));
    }
    if (days > 0) {
        result.push(days + (days === 1 ? ' day' : ' days'));
    }
    if (hours > 0) {
        result.push(hours + (hours === 1 ? ' hour' : ' hours'));
    }
    if (minutes > 0) {
        result.push(minutes + (minutes === 1 ? ' minute' : ' minutes'));
    }
    if (seconds > 0) {
        result.push(seconds + (seconds === 1 ? ' second' : ' seconds'));
    }

    return result.join(', ');
}

export function UtilsCleanEmptyStrings(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(UtilsCleanEmptyStrings);
    }

    const cleanedObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === null) continue
            if (typeof value === 'string' && value.length === 0) continue

            cleanedObj[key] = UtilsCleanEmptyStrings(value);
        }
    }

    return cleanedObj;
}
